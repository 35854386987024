import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './warehouse.css';

function Warehouse() {
    const [selectedDate, setSelectedDate] = useState('');
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const fetchReport = useCallback(async (date) => {
        if (!date) return;
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`/api/bookings/daily-report/${date}`);
            setReport(response.data.bookings);
            navigate(`?date=${date}`, { replace: true });
        } catch (error) {
            console.error('Error fetching warehouse report:', error);
            setError('Failed to load report. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const date = params.get('date');
        if (date) {
            setSelectedDate(date);
        }
    }, [location.search]);

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const calculateLinen = (singleBeds, doubleBeds, bathrooms) => {
        const roundedBathrooms = Math.ceil(bathrooms);
        return {
            BS: doubleBeds > 0 ? `${doubleBeds} BS` : null,
            smallBS: singleBeds > 0 ? `${singleBeds} SMALL BS` : null,
            DC: doubleBeds > 0 ? `${doubleBeds} DC` : null,
            smallDC: singleBeds > 0 ? `${singleBeds} SMALL DC` : null,
            PC: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds * 2 + doubleBeds * 4} PC` : null,
            BT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} BT` : null,
            HT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} HT` : null,
            FT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} FT` : null,
            BM: roundedBathrooms > 0 ? `${roundedBathrooms} BM` : null,
        };
    };

    const calculateMDLLinen = (singleBeds, doubleBeds, bathrooms) => {
        const roundedBathrooms = Math.floor(bathrooms);
        return {
            BS: doubleBeds > 0 ? `${doubleBeds} BS` : null,
            smallBS: singleBeds > 0 ? `${singleBeds} SMALL BS` : null,
            DC: doubleBeds > 0 ? `${doubleBeds} DC` : null,
            smallDC: singleBeds > 0 ? `${singleBeds} SMALL DC` : null,
            PC: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} PC` : null,
            BT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} BT` : null,
            HT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} HT` : null,
            FT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} FT` : null,
            beachTowel: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} BEACH` : null,
            BM: roundedBathrooms > 0 ? `${roundedBathrooms} BM` : null,
        };
    };

    const getDutyInfo = (booking) => {
        let dutyText = '';
        let linenText = '';
        let mdlText = '';

        if (booking.is_laundry) {
            if (booking.is_linen_hire) {
                dutyText = <span style={{ backgroundColor: 'yellow' }}>Pack Linen Hire</span>;
                const linenCounts = calculateLinen(booking.single_beds, booking.double_beds, booking.bathrooms);
                linenText = Object.values(linenCounts).filter(Boolean).join(', ');
            } else {
                dutyText = 'Pack Linens';
            }
        }

        if (booking.client_name === "Mai De Luxe") {
            const mdlCounts = calculateMDLLinen(booking.single_beds, booking.double_beds, booking.bathrooms);
            mdlText = (
                <span>
                    <span style={{ backgroundColor: 'silver' }}>Prepare From MDL:</span> {Object.values(mdlCounts).filter(Boolean).join(', ')}
                </span>
            );
        }

        if (booking.booking_type === 'Pickup') {
            dutyText = 'Prepare empty blue bag with tag';
        } else if (booking.booking_type === 'Dropoff') {
            dutyText = 'Prepare Linens';
        }

        const amenitiesText = booking.is_amenities ? (
            'Pack Amenities'
        ) : (
            <span style={{ backgroundColor: 'red' }}>
                NO AMENITIES
            </span>
        );

        return {
            duty: dutyText,
            amenities: amenitiesText,
            linenText,
            mdlText,
        };
    };

    const sortReport = (report) => {
        const linenHireBookings = report.filter(booking => booking.is_linen_hire);
        const mdlBookings = report.filter(booking => booking.client_name === "Mai De Luxe" && !booking.is_linen_hire);
        const amenitiesBookings = report.filter(booking => booking.is_amenities && !booking.is_linen_hire && booking.client_name !== "Mai De Luxe");
        const noAmenitiesBookings = report.filter(booking => !booking.is_amenities && !booking.is_linen_hire && booking.client_name !== "Mai De Luxe");

        return [...linenHireBookings, ...mdlBookings, ...amenitiesBookings, ...noAmenitiesBookings];
    };

    const sortedReport = sortReport(report);

    return (
        <div className="warehouse-container">
            <h2>Warehouse Duty Report</h2>
            <div className="date-selector">
                <label>Select Date:</label>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="date-input"
                />
                <button onClick={() => fetchReport(selectedDate)} className="fetch-button">
                    Fetch Report
                </button>
            </div>

            {loading ? (
                <div>Loading report...</div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : (
                <div className="report-output">
                    {sortedReport.length > 0 ? (
                        sortedReport.map((booking, index) => {
                            const dutyInfo = getDutyInfo(booking);

                            if (!dutyInfo.duty && !dutyInfo.mdlText) {
                                return null;
                            }

                            return (
                                <div key={index} className="warehouse-entry">
                                    <h3>
                                        <Link to={`/bookings/${booking.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {index + 1}. {booking.client_name} - {booking.building_name} - Unit {booking.unit_number} ({booking.bedrooms === 0 ? 'S' : booking.bedrooms} - {booking.bathrooms})
                                        </Link>
                                    </h3>
                                    <p>
                                        {dutyInfo.duty}
                                        {dutyInfo.amenities && <span> + {dutyInfo.amenities}</span>}
                                    </p>
                                    {dutyInfo.linenText && <p>{dutyInfo.linenText}</p>}
                                    {dutyInfo.mdlText && <p>{dutyInfo.mdlText}</p>}
                                </div>
                            );
                        })
                    ) : (
                        <p>No report available for the selected date.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Warehouse;
