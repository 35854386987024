import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './addLaundry.css';
import LoadingPopup from '../LoadingPopup';

function EditLaundry() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [clientName, setClientName] = useState('');
    const [buildingName, setBuildingName] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [status, setStatus] = useState('');
    const [dropOffItems, setDropOffItems] = useState([]);
    const [pickupItems, setPickupItems] = useState([]);
    const [extraItemOptions, setExtraItemOptions] = useState([]);
    const [selectedExtraDropOffItem, setSelectedExtraDropOffItem] = useState(null);
    const [selectedExtraPickupItem, setSelectedExtraPickupItem] = useState(null);
    const [dropOffExtraItems, setDropOffExtraItems] = useState([]);
    const [pickupExtraItems, setPickupExtraItems] = useState([]);
    const [comment, setComment] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [propertyId, setPropertyId] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [shouldNavigate, setShouldNavigate] = useState(false);

    const statusOptions = [
        { value: 'created', label: 'Waiting for warehouse confirmation' },
        { value: 'before_photo', label: 'Awaiting Before Photos' },
        { value: 'flagged', label: 'Incorrect Warehouse Confirmation' },
        { value: 'processing', label: 'Processing' },
        { value: 'completed', label: 'Packed & Ready' },
        { value: 'delivered', label: 'Delivered' }

    ];
    useEffect(() => {
        const fetchLaundryData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/api/laundry/${id}/details`);
                const data = response.data;

                setClientName(data.client_name);
                setBuildingName(data.building_name);
                setUnitNumber(data.unit_number);
                setCreatedAt(new Date(data.created_at).toLocaleDateString());
                setStatus(data.status);
                setComment(data.comment);

                // Set new state variables
                setBookingId(data.booking_id);
                setPropertyId(data.property_id);
                setBedrooms(data.bedrooms);
                setBathrooms(data.bathrooms);

                setDropOffItems(data.laundry_items.filter(item => item.quantity_dropoff !== null).map(item => ({
                    name: item.name,
                    quantity: item.quantity_dropoff,
                    laundry_item_id: item.laundry_item_id
                })));

                setPickupItems(data.laundry_items.filter(item => item.quantity_pickup !== null).map(item => ({
                    name: item.name,
                    quantity: item.quantity_pickup,
                    laundry_item_id: item.laundry_item_id
                })));

                const allItemsResponse = await axios.get('/api/laundry/laundry-items');
                const extraItems = allItemsResponse.data
                    .filter(item => !data.laundry_items.some(existing => existing.name === item.name))
                    .map(item => ({ value: item.id, label: item.name }));
                setExtraItemOptions(extraItems);
            } catch (error) {
                console.error("Error fetching laundry details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLaundryData();
    }, [id]);

    const handleQuantityChange = (e, index, list, setList) => {
        const updatedItems = [...list];
        updatedItems[index].quantity = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        setList(updatedItems);
    };

    const handleAddExtraItem = (selectedItem, setItems, items) => {
        if (selectedItem && !items.some(item => item.laundry_item_id === selectedItem.value)) {
            setItems([{ name: selectedItem.label, laundry_item_id: selectedItem.value, quantity: '' }, ...items]); // Set quantity as empty string
        }
    };

    const handleRemoveExtraItem = (index, setItems, items) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure that even quantities set to 0 are included in the submission
        const formattedDropOffItems = dropOffItems.concat(dropOffExtraItems).map(item => ({
            laundry_item_id: item.laundry_item_id,
            quantity: item.quantity === '' ? 0 : item.quantity // Convert blank quantity to 0 for backend
        }));

        const formattedPickupItems = pickupItems.concat(pickupExtraItems).map(item => ({
            laundry_item_id: item.laundry_item_id,
            quantity: item.quantity === '' ? 0 : item.quantity // Convert blank quantity to 0 for backend
        }));

        const laundryCountData = {
            status,
            comment,
            drop_off_items: formattedDropOffItems,
            pickup_items: formattedPickupItems
        };
        setIsLoading(true);

        try {
            const response = await axios.put(`/api/laundry/${id}`, laundryCountData);
            if (response.status === 200) {
                setFeedbackMessage('Laundry count updated successfully.');
                setShouldNavigate(true);
            } else {
                setFeedbackMessage('Failed to update laundry count.');
            }
        } catch (error) {
            console.error('Error updating laundry count:', error);
            setFeedbackMessage('Failed to update laundry count.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClosePopup = () => {
        if (shouldNavigate) {
            navigate(`/laundry/${id}`);
        }
        setFeedbackMessage(''); // Reset feedback message
    };

    return (
        <div className="add-laundry-container">
            <h2>Edit Laundry Count</h2>

            {/* Client and Property Information */}
            <div className="booking-details">
                <div className="column">
                    <p><strong>Booking ID:</strong> #{bookingId.toString().padStart(6, '0')}</p>
                    <p><strong>Created At:</strong> {createdAt}</p>
                </div>
                <div className="column">
                    <p><strong>Property ID:</strong> #{propertyId.toString().padStart(6, '0')}</p>
                    <p><strong>Address:</strong> {`${clientName} - ${buildingName} - Unit ${unitNumber} (${bedrooms === 0 ? 'S' : bedrooms} - ${bathrooms || 'N/A'})`}</p>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Status</label>
                    <Select
                        options={statusOptions}
                        value={statusOptions.find(option => option.value === status)}
                        onChange={(selectedOption) => setStatus(selectedOption.value)}
                        className="select-status"
                    />
                </div>

                <div className="laundry-lists-container">
                    <div className="list-section">
                        <h3>Drop Off Items</h3>
                        {dropOffItems.map((item, index) => (
                            <div key={index} className="item-row">
                                <span className="item-name">{item.name}</span>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleQuantityChange(e, index, dropOffItems, setDropOffItems)}
                                    placeholder="Quantity"
                                    className="quantity-input"
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        ))}
                        <h4>Add Extra Drop Off Items</h4>
                        {dropOffExtraItems.length > 0 && (
                            <div className="extra-items-list">
                                {dropOffExtraItems.map((item, index) => (
                                    <div key={index} className="item-row">
                                        <span className="item-name">{item.name}</span>
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(e, index, dropOffExtraItems, setDropOffExtraItems)}
                                            placeholder="Quantity"
                                            className="quantity-input"
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => {
                                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        <span
                                            className="remove-extra-item-button"
                                            onClick={() => handleRemoveExtraItem(index, setDropOffExtraItems, dropOffExtraItems)}
                                            style={{ cursor: 'pointer', color: 'grey', fontSize: '1.5rem' }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="extra-item-selection">
                            <Select
                                options={extraItemOptions}
                                onChange={setSelectedExtraDropOffItem}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button
                                type="button"
                                onClick={() => handleAddExtraItem(selectedExtraDropOffItem, setDropOffExtraItems, dropOffExtraItems)}
                                className="add-extra-item-button"
                            >
                                + Extra Item
                            </button>
                        </div>
                    </div>

                    <div className="list-section">
                        <h3>Pickup Items</h3>
                        {pickupItems.map((item, index) => (
                            <div key={index} className="item-row">
                                <span className="item-name">{item.name}</span>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleQuantityChange(e, index, pickupItems, setPickupItems)}
                                    placeholder="Quantity"
                                    className="quantity-input"
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        ))}
                        <h4>Add Extra Pickup Items</h4>
                        {pickupExtraItems.length > 0 && (
                            <div className="extra-items-list">
                                {pickupExtraItems.map((item, index) => (
                                    <div key={index} className="item-row">
                                        <span className="item-name">{item.name}</span>
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(e, index, pickupExtraItems, setPickupExtraItems)}
                                            placeholder="Quantity"
                                            className="quantity-input"
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => {
                                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        <span
                                            className="remove-extra-item-button"
                                            onClick={() => handleRemoveExtraItem(index, setPickupExtraItems, pickupExtraItems)}
                                            style={{ cursor: 'pointer', color: 'grey', fontSize: '1.5rem' }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="extra-item-selection">
                            <Select
                                options={extraItemOptions}
                                onChange={setSelectedExtraPickupItem}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button
                                type="button"
                                onClick={() => handleAddExtraItem(selectedExtraPickupItem, setPickupExtraItems, pickupExtraItems)}
                                className="add-extra-item-button"
                            >
                                + Extra Item
                            </button>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Comment</label>
                    <textarea
                        rows="3"
                        placeholder="Add any comments related to this laundry count"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="comment-field"
                    />
                </div>

                <button type="submit" className="submit-button">Update Laundry Count</button>
            </form>
            <LoadingPopup
                isLoading={isLoading}
                feedbackMessage={feedbackMessage}
                onClose={handleClosePopup}
            />
        </div>
    );
}

export default EditLaundry;
