import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import "./standardQuantityPopup.css";

const predefinedItems = [
    "Bedsheet",
    "Duvet Cover",
    "Pillowcase",
    "Bath Towel",
    "Hand Towel",
    "Face Towel",
    "Bathmat"
];

function StandardQuantityPopup({ propertyId, onClose }) {
    const [standardQuantities, setStandardQuantities] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedExtraItem, setSelectedExtraItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    useEffect(() => {
        const fetchStandardQuantities = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/properties/property-sq/${propertyId}`);
                const savedQuantities = response.data;

                // Create a map of saved quantities for quick access
                const savedQuantitiesMap = savedQuantities.reduce((map, item) => {
                    map[item.laundry_item_name] = item;
                    return map;
                }, {});

                // Merge predefined items with saved quantities
                const mergedQuantities = predefinedItems.map((name) => {
                    if (savedQuantitiesMap[name]) {
                        return savedQuantitiesMap[name];
                    }
                    return {
                        property_id: propertyId,
                        laundry_item_name: name,
                        count: 0
                    };
                });

                // Add any additional saved items not in predefined items
                savedQuantities.forEach((item) => {
                    if (!predefinedItems.includes(item.laundry_item_name)) {
                        mergedQuantities.push(item);
                    }
                });

                // Sort items with predefined items first, followed by custom items alphabetically
                setStandardQuantities(
                    mergedQuantities.sort((a, b) => {
                        const predefinedIndexA = predefinedItems.indexOf(a.laundry_item_name);
                        const predefinedIndexB = predefinedItems.indexOf(b.laundry_item_name);
                        if (predefinedIndexA !== -1 && predefinedIndexB !== -1) {
                            return predefinedIndexA - predefinedIndexB;
                        }
                        if (predefinedIndexA !== -1) return -1;
                        if (predefinedIndexB !== -1) return 1;
                        return a.laundry_item_name.localeCompare(b.laundry_item_name);
                    })
                );
            } catch (error) {
                console.error("Error fetching standard quantities:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchAvailableItems = async () => {
            try {
                const response = await axios.get("/api/laundry/laundry-items");
                setAvailableItems(
                    response.data.map((item) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id
                    }))
                );
            } catch (error) {
                console.error("Error fetching laundry items:", error);
            }
        };

        fetchStandardQuantities();
        fetchAvailableItems();
    }, [propertyId]);

    const handleQuantityChange = (itemName, newCount) => {
        setStandardQuantities((prev) =>
            prev.map((item) =>
                item.laundry_item_name === itemName ? { ...item, count: newCount } : item
            )
        );
    };

    const handleAddItem = () => {
        if (selectedExtraItem) {
            setStandardQuantities((prev) => [
                ...prev,
                {
                    property_id: propertyId,
                    laundry_item_name: selectedExtraItem.value,
                    count: 0
                }
            ]);
            setSelectedExtraItem(null);
        }
    };

    const handleRemoveItem = (itemName) => {
        setStandardQuantities((prev) =>
            prev.filter((item) => item.laundry_item_name !== itemName)
        );
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await axios.post(`/api/properties/property-sq/${propertyId}`, {
                standard_quantities: standardQuantities
            });
            setFeedbackMessage("Standard quantities saved successfully!");
        } catch (error) {
            console.error("Error saving standard quantities:", error);
            setFeedbackMessage("Failed to save standard quantities.");
        } finally {
            setLoading(false);
        }
    };

    const closeFeedback = () => {
        setFeedbackMessage("");
        onClose();
    };

    return (
        <div className="popup-overlay">
            <div className="sq-popup">
                <span className="popup-close" onClick={onClose}>
                    &times;
                </span>
                {loading || feedbackMessage ? (
                    <div className="loading-feedback">
                        {loading ? (
                            <>
                                <div className="spinner"></div>
                                <p>Loading...</p>
                            </>
                        ) : (
                            <>
                                <p>{feedbackMessage}</p>
                                <button onClick={closeFeedback} className="ok-button">
                                    OK
                                </button>
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        <h2>Manage Standard Quantities</h2>
                        <div className="standard-quantity-container">
                            {standardQuantities.map((item, index) => (
                                <div key={index} className="standard-quantity-row">
                                    <span className="item-name">{item.laundry_item_name}</span>
                                    <input
                                        type="number"
                                        min="0"
                                        value={item.count}
                                        onChange={(e) =>
                                            handleQuantityChange(item.laundry_item_name, +e.target.value)
                                        }
                                        placeholder="Set Quantity"
                                        className="quantity-input"
                                    />
                                    <button
                                        className="remove-extra-item-button"
                                        onClick={() => handleRemoveItem(item.laundry_item_name)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="extra-item-selection">
                            <Select
                                options={availableItems.filter(
                                    (option) =>
                                        !standardQuantities.some(
                                            (item) => item.laundry_item_name === option.value
                                        )
                                )}
                                onChange={setSelectedExtraItem}
                                value={selectedExtraItem}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button
                                type="button"
                                className="add-extra-item-button"
                                onClick={handleAddItem}
                            >
                                + Add Item
                            </button>
                        </div>

                        <div className="popup-actions">
                            <button onClick={onClose} className="cancel-button">
                                Cancel
                            </button>
                            <button onClick={handleSave} className="save-button">
                                Save
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default StandardQuantityPopup;
