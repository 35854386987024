import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBuilding, faCalendarAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DailyBookings from './Bookings/DailyBookings';  // Corrected import path for DailyBookings component
import './home.css';

function Home() {
  const [stats, setStats] = useState({
    clients: 0,
    properties: 0,
    bookings: 0,
    teamMembers: 0,
  });
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the first name and user role from localStorage
    const storedFirstName = localStorage.getItem('firstName');
    const userRole = localStorage.getItem('userRole');
    
    setFirstName(storedFirstName);

    // Redirect to the Laundry page if the user role is 'warehouse'
    if (userRole === 'warehouse') {
      navigate('/laundry');
      return;
    }

    if (userRole === 'driver') {
      navigate('/drivers');
      return;
    }

    // Fetch the count statistics for Clients, Properties, Bookings, Team Members
    const fetchStats = async () => {
      try {
        const [clientsRes, propertiesRes, bookingsRes, teamRes] = await Promise.all([
          axios.get('/api/clients/count'),
          axios.get('/api/properties/count'),
          axios.get('/api/bookings/count'),
          axios.get('/api/users/count'),
        ]);

        setStats({
          clients: clientsRes.data.count || 0,
          properties: propertiesRes.data.count || 0,
          bookings: bookingsRes.data.count || 0,
          teamMembers: teamRes.data.count || 0,
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('There was an issue fetching the data. Please try again later.');
      }
    };

    fetchStats();
  }, [navigate]);

  const today = new Date().toISOString().split('T')[0];  // Get today's date

  return (
    <div className="home-dashboard">
      <h1>Welcome {firstName ? firstName : 'Admin'}! Have a good day ahead!</h1>
      <p>Let's manage our clients, properties, bookings, and team members from here.</p>

      {error ? <div className="error-message">{error}</div> : (
        <div className="stats-cards">
          <Link to="/clients" className="card">
            <div className="card-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <div className="card-info">
              <h3>{stats.clients}</h3>
              <p>Clients</p>
            </div>
          </Link>

          <Link to="/properties" className="card">
            <div className="card-icon">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="card-info">
              <h3>{stats.properties}</h3>
              <p>Properties</p>
            </div>
          </Link>

          <Link to="/bookings" className="card">
            <div className="card-icon">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <div className="card-info">
              <h3>{stats.bookings}</h3>
              <p>Bookings</p>
            </div>
          </Link>

          <Link to="/team" className="card">
            <div className="card-icon">
              <FontAwesomeIcon icon={faUserFriends} />
            </div>
            <div className="card-info">
              <h3>{stats.teamMembers}</h3>
              <p>Team Members</p>
            </div>
          </Link>
        </div>
      )}

      {/* Reuse the DailyBookings component */}
      <div className="today-schedule">
        <DailyBookings customDate={today} />
      </div>
    </div>
  );
}

export default Home;