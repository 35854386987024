import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './team.css';

function Team() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedEid, setCopiedEid] = useState(null);

  const navigate = useNavigate();

  const roleOrder = ['cleaner', 'driver', 'warehouse', 'part-time cleaner', 'admin', 'manager'];

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get('/api/team');
        setTeamMembers(response.data);
        setFilteredTeamMembers(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching team members:', error);
        setError('Error fetching team members');
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = teamMembers.filter(member =>
      member.first_name.toLowerCase().includes(query) ||
      member.last_name.toLowerCase().includes(query)
    );
    setFilteredTeamMembers(filtered);
  };

  const handleAddTeam = () => {
    navigate('/team/new');
  };

  const handleEditTeam = (id) => {
    navigate(`/team/edit/${id}`);
  };

  const handleCopyEid = (eid) => {
    if (!eid) return;
    navigator.clipboard.writeText(eid)
      .then(() => {
        setCopiedEid(eid);
        setTimeout(() => setCopiedEid(null), 2000);
      })
      .catch(err => console.error('Failed to copy EID:', err));
  };

  const formatRole = (role) => {
    return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() + 's';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const groupMembersByRole = (members) => {
    const grouped = members.reduce((acc, member) => {
      const role = member.role_name.toLowerCase();
      if (!acc[role]) {
        acc[role] = [];
      }
      acc[role].push(member);
      return acc;
    }, {});

    return roleOrder.reduce((acc, role) => {
      if (grouped[role]) {
        acc[role] = grouped[role];
      }
      return acc;
    }, {});
  };

  if (loading) {
    return <div>Loading team members...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const groupedByRole = groupMembersByRole(filteredTeamMembers);

  return (
    <div className="team-container">
      <div className="header-row">
        <h2>Team Members</h2>
        <div className="search-add-container">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={handleSearch}
            className="search-input"
          />
          <button className="add-button" onClick={handleAddTeam}>+ Add Team Member</button>
        </div>
      </div>

      {Object.keys(groupedByRole).length === 0 ? (
        <p>No team members found.</p>
      ) : (
        Object.keys(groupedByRole).map((role) => (
          <div key={role} className="role-group">
            <h3>{formatRole(role)}</h3>
            <ul className="team-list">
              {groupedByRole[role].map((member) => (
                <li key={member.id} className="team-member">
                  <div className="member-card">
                    {/* Show edit icon only for non-manager roles */}
                    {role !== 'manager' && role !== 'admin' && (
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="edit-icon"
                        onClick={() => handleEditTeam(member.id)}
                      />
                    )}
                    <h4>{`${member.first_name} ${member.last_name}`}</h4>

                    {/* EID with copy functionality */}
                    <div className="eid-section">
                      <p>EID: {member.eid || 'N/A'}</p>
                      {member.eid && (
                        <FontAwesomeIcon
                          icon={copiedEid === member.eid ? faCheck : faCopy}
                          className="copy-icon"
                          onClick={() => handleCopyEid(member.eid)}
                          title="Copy EID"
                        />
                      )}
                    </div>

                    <p>Joined on: {formatDate(member.created_at)}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  );
}

export default Team;