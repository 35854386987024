import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import './confirmLaundry.css';

function ConfirmLaundry() {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const propertyId = searchParams.get('propertyId');
    const propertyDisplay = searchParams.get('propertyDisplay');
    const navigate = useNavigate();
    const location = useLocation();

    const [step, setStep] = useState(location.state?.startStep || 1);
    const [pickupItems, setPickupItems] = useState([]);
    const [extraItemOptions, setExtraItemOptions] = useState([]);
    const [selectedExtraItem, setSelectedExtraItem] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [preloadedPhotos, setPreloadedPhotos] = useState([]);


    useEffect(() => {
        const fetchLaundryData = async () => {
            try {
                const pickupResponse = await axios.get(`/api/laundry/${id}/pickup-items`);
                const existingPickupItems = pickupResponse.data.map(item => ({
                    ...item,
                    quantity_warehouse_confirmation: ''
                }));
                setPickupItems(existingPickupItems);

                const itemsResponse = await axios.get('/api/laundry/laundry-items');
                const availableExtraOptions = itemsResponse.data
                    .filter(item => !existingPickupItems.some(existing => existing.name === item.name))
                    .map(item => ({ value: item.id, label: item.name }));
                setExtraItemOptions(availableExtraOptions);

                // Fetch "before" photos
                const beforePhotosResponse = await axios.get(`/api/attachments/laundry/${id}/before`);
                setPreloadedPhotos(beforePhotosResponse.data.map(photo => photo.image_url));
            } catch (error) {
                console.error("Error fetching laundry data:", error);
            }
        };

        fetchLaundryData();
    }, [id]);


    const handleQuantityChange = (e, index) => {
        const updatedItems = [...pickupItems];
        updatedItems[index].quantity_warehouse_confirmation = e.target.value ? parseInt(e.target.value, 10) : '';
        setPickupItems(updatedItems);
    };

    const handleAddExtraItem = async () => {

        try {
            await axios.post(`/api/laundry/${id}/add-missed-item`, {
                laundry_item_id: selectedExtraItem.value,
                quantity_dropoff: 0
            });

            setPickupItems([
                ...pickupItems,
                {
                    laundry_item_id: selectedExtraItem.value,
                    name: selectedExtraItem.label,
                    quantity_dropoff: 0,
                    quantity_pickup: null,
                    quantity_warehouse_confirmation: ''
                }
            ]);

            setSelectedExtraItem(null);
        } catch (error) {
            console.error("Error adding missed laundry item:", error);
        }
    };

    const handleConfirmationSubmit = async (e) => {
        e.preventDefault();

        const confirmedItems = pickupItems.map(item => ({
            laundry_item_id: item.laundry_item_id,
            quantity_warehouse_confirmation: item.quantity_warehouse_confirmation !== '' ? item.quantity_warehouse_confirmation : 0
        }));

        try {
            const response = await axios.post(`/api/laundry/${id}/confirm`, { confirmedItems });
            if (response.status === 200) {
                setStep(2);
            }
        } catch (error) {
            console.error('Error confirming laundry count:', error);;
        }
    };

    const handlePhotoSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (photos.length === 0 && preloadedPhotos.length > 0) {
            // Call the new route to check counts and update the status
            try {
                const response = await axios.post(`/api/laundry/status-update/${id}`);
                if (response.status === 200) {
                    setFeedbackMessage(response.data.message); // Display the backend response message
                }
            } catch (error) {
                console.error("Error updating status with preloaded photos:", error);
                setFeedbackMessage("Failed to update status with preloaded photos. Please try again.");
            } finally {
                setLoading(false);
            }
            return;
        }

        if (photos.length === 0) {
            setFeedbackMessage("Please upload at least one photo or use preloaded photos.");
            setLoading(false);
            return;
        }

        // Handle new photo uploads
        const formData = new FormData();
        photos.forEach(photo => formData.append('photos', photo));

        try {
            const response = await axios.post(`/api/attachments/laundry/before/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.status === 201) {
                setFeedbackMessage('Photos uploaded successfully.');
            }
        } catch (error) {
            console.error("Error uploading photos:", error);
            setFeedbackMessage("Failed to upload photos. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const closeFeedbackOverlay = () => {
        // Check if feedback message is related to missing photos
        if (feedbackMessage.includes("upload at least one photo") || feedbackMessage.includes("Failed to upload")) {
            setFeedbackMessage(''); // Clear feedback message without navigating
        } else {
            // Redirect only if the feedback indicates success or a non-critical message
            setFeedbackMessage(''); // Clear feedback message
            navigate('/laundry');
        }
    };
    

    return (
        <div className="confirm-laundry-container">
            {/* Loading and Feedback Overlay */}
            {/* Loading and Feedback Overlay */}
            {(loading || feedbackMessage) && (
                <div className="loading-overlay">
                    <div className="loading-box">
                        {loading ? (
                            <>
                                <div className="spinner"></div>
                                <p>Loading...</p>
                            </>
                        ) : (
                            <>
                                <p>{feedbackMessage}</p>
                                <button className="ok-button" onClick={closeFeedbackOverlay}>
                                    OK
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {step === 1 && (
                <>
                    <h2>Confirm Laundry Pickup</h2>
                    <h4>{propertyDisplay}</h4>
                    <h4>Property ID: #{propertyId.toString().padStart(3, '0')}</h4>
                    <form onSubmit={handleConfirmationSubmit}>
                        <div className="laundry-lists-container">
                            <div className="list-section">
                                <h3>Confirm Pickup Items</h3>
                                {pickupItems.length > 0 ? (
                                    pickupItems.map((item, index) => (
                                        <div key={item.laundry_item_id} className="item-row">
                                            <span className="item-name">{item.name}</span>
                                            <input
                                                type="number"
                                                value={item.quantity_warehouse_confirmation}
                                                onChange={(e) => handleQuantityChange(e, index)}
                                                placeholder="Confirm Quantity"
                                                className="quantity-input"
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No items available to confirm.</p>
                                )}
                            </div>
                        </div>
                        <div className="extra-item-selection">
                            <h4>Add Extra Item</h4>
                            <Select
                                options={extraItemOptions}
                                onChange={setSelectedExtraItem}
                                value={selectedExtraItem}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button type="button" className="add-extra-item-button" onClick={handleAddExtraItem}>
                                + Add Extra Item
                            </button>
                        </div>
                        <button type="submit" className="submit-button">Submit Confirmation</button>
                    </form>
                </>
            )}

            {step === 2 && (
                <>
                    <h2>Upload Before Photos</h2>
                    <form onSubmit={handlePhotoSubmit}>
                        <div className="form-group">
                            <label>Upload Photos</label>
                            <input
                                type="file"
                                multiple
                                onChange={(e) => setPhotos([...photos, ...Array.from(e.target.files)])}
                            />
                            <div className="photo-preview-container">
                                {/* Display preloaded photos */}
                                {preloadedPhotos.map((photoUrl, index) => (
                                    <div key={`preloaded-${index}`} className="photo-preview">
                                        <img src={photoUrl} alt={`Preloaded ${index}`} />
                                    </div>
                                ))}

                                {/* Display newly added photos */}
                                {photos.map((photo, index) => (
                                    <div key={`uploaded-${index}`} className="photo-preview">
                                        <img src={URL.createObjectURL(photo)} alt={`Preview ${index}`} />
                                        <button type="button" onClick={() => setPhotos(photos.filter((_, i) => i !== index))}>X</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button type="submit" className="submit-button">Submit Photos</button>
                    </form>
                </>
            )}
        </div>
    );
}

export default ConfirmLaundry;
