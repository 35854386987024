import React, { useState, useEffect, useCallback } from 'react';
import './apartmentSetup.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload, faTrash, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function ApartmentSetup({ setupPhotos, propertyId }) {
  const [isOpen, setIsOpen] = useState(false); // Modal state
  const [currentIndex, setCurrentIndex] = useState(0); // Index of the clicked thumbnail
  const [selectedFiles, setSelectedFiles] = useState([]); // Store selected files for upload
  const [photos, setPhotos] = useState([]); // Initialize empty state for photos
  const [isEditMode, setIsEditMode] = useState(false); // Edit mode state
  const [isUploading, setIsUploading] = useState(false); // Upload state

  // Fetch photos from the backend, using useCallback to avoid recreating the function
  const fetchPhotos = useCallback(async () => {
    try {
      const response = await axios.get(`/api/properties/${propertyId}/setup-photos`);
      setPhotos(response.data);
    } catch (error) {
      console.error('Error fetching setup photos:', error);
    }
  }, [propertyId]);

  // Initialize photos state on component mount
  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  // Convert photos to a format suitable for ImageGallery
  const images = photos.map((photo) => ({
    original: photo.file_url,
    thumbnail: photo.file_url,
  }));

  // Open the modal and set the current index to the clicked thumbnail's index
  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // Handle file selection and add previews
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({ file, preview: URL.createObjectURL(file) }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Remove a selected file from preview
  const removePhoto = (index) => {
    const newFiles = [...selectedFiles];
    URL.revokeObjectURL(newFiles[index].preview); // Release memory for preview URL
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) return;

    const formData = new FormData();
    selectedFiles.forEach(({ file }) => {
      formData.append('photos', file); // Appending photos with the key 'photos'
    });

    try {
      setIsUploading(true);

      // Send files to the backend API
      await axios.post(`/api/attachments/properties/${propertyId}/photos`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Refetch photos from server to update state with newly uploaded photos
      await fetchPhotos();

      setSelectedFiles([]); // Clear selected files
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsUploading(false);
    }
  };

  // Handle photo deletion
  const handleDeletePhoto = async (photoId) => {
    const confirmed = window.confirm('Are you sure you want to delete this photo?');
    if (!confirmed) return;

    try {
      await axios.delete(`/api/attachments/properties/${propertyId}/photos/${photoId}`);

      // Update photos state to remove the deleted photo
      setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  return (
    <div className="details-card setup-photos-section full-width">
      <h2>Apartment Standard Setup</h2>

      {/* Photos Grid */}
      {photos.length > 0 ? (
        <div className="photos-grid">
          {photos.map((photo, index) => (
            <div key={photo.id || index} className="photo-item">
              <img
                src={photo.file_url}
                alt={`Setup ${index}`}
                className="thumbnail"
                onClick={() => openModal(index)}
              />
              {isEditMode && (
                <button className="delete-photo-button" onClick={() => handleDeletePhoto(photo.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="no-photos-message">
          <FontAwesomeIcon icon={faCamera} className="placeholder-icon" />
          <p>No setup photos available.</p>
        </div>
      )}

      {/* Modal for Viewing Photos */}
      {isOpen && (
        <div className="photo-modal-overlay" onClick={closeModal}>
          <div className="photo-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <ImageGallery
              items={images}
              startIndex={currentIndex}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              slideDuration={400}
              showNav={true}
            />
          </div>
        </div>
      )}

      {/* Edit Photos Section */}
      <div className="edit-photos-section">
        <button className="add-note-button edit-photos-button" onClick={toggleEditMode}>
          {isEditMode ? (
            <>
              <FontAwesomeIcon icon={faSave} /> Save
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faEdit} /> Edit Photos
            </>
          )}
        </button>

        {isEditMode && (
          <div className="upload-area">
            <input
              id="upload-input"
              type="file"
              multiple
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <button
              className="upload-button"
              onClick={() => document.getElementById('upload-input').click()}
            >
              <FontAwesomeIcon icon={faUpload} /> Upload Photos
            </button>

            {/* Preview selected files */}
            <div className="photo-preview-container">
              {selectedFiles.map((file, index) => (
                <div key={index} className="photo-preview">
                  <img src={file.preview} alt={`Preview ${index}`} />
                  <button type="button" onClick={() => removePhoto(index)}>X</button>
                </div>
              ))}
            </div>

            {selectedFiles.length > 0 && (
              <button className="confirm-upload-apartment" onClick={handleFileUpload} disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Confirm Upload'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ApartmentSetup;
