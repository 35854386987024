import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./summaryReport.css";

const SummaryReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const userRole = localStorage.getItem("userRole"); 

  const isOffPeak = useCallback((booking) => {
    const endTime = new Date(booking.booking_end);
    const startTime = new Date(booking.booking_start);

    return (
      endTime.getHours() < 11 || (endTime.getHours() === 11 && endTime.getMinutes() < 30) ||
      startTime.getHours() >= 15
    );
  }, []);

  const calculateBookingPrice = useCallback((booking, property) => {
    const { booking_status, booking_type, booking_start, booking_end, is_amenities, required_cleaners } = booking;

    if (booking_status === "Pending") {
      return 0;
    } else if (
      booking_status === "Last Minute Cancelled" ||
      booking_status === "Last Minute Rescheduled"
    ) {
      return 100;
    }

    if (booking_type === "Delivery" || booking_type === "Pickup") {
      let basePrice = 20;
      if (is_amenities) {
        basePrice += 30 * (property.bedrooms === 0 ? 1 : property.bedrooms);
      }
      return basePrice;
    }

    if (booking_type === "Laundry Service Only") {
      let basePrice = parseFloat(booking.laundry_cost || 0);
      return basePrice;
    }

    const startTime = new Date(booking_start);
    const endTime = new Date(booking_end);
    const durationHours = (endTime - startTime) / (1000 * 60 * 60);

    if (booking_type === "Hourly Cleaning with Materials" || booking_type === "Deep Cleaning") {
      let basePrice = durationHours * 45 * required_cleaners;
      if (is_amenities) {
        basePrice += 30 * (property.bedrooms === 0 ? 1 : property.bedrooms);
      }
      if (booking.is_laundry) {
        basePrice += parseFloat(booking.laundry_cost || 0);
      }
      return basePrice;
    } else if (booking_type === "Hourly Cleaning without Materials") {
      let basePrice = durationHours * 40 * required_cleaners;
      if (is_amenities) {
        basePrice += 30 * (property.bedrooms === 0 ? 1 : property.bedrooms);
      }
      if (booking.is_laundry) {
        basePrice += parseFloat(booking.laundry_cost || 0);
      }
      return basePrice;
    } else if (booking_type === "All Inclusive Package" || booking_type === "All Inclusive Package + Deep Cleaning") {
      let basePrice = property.is_custom_price
        ? parseFloat(property.custom_price) || 0
        : ({
          0: 198,
          1: 220,
          2: 320,
          3: 420,
          4: 520,
        }[property.bedrooms] || "No Price Set");

      if (typeof basePrice !== "number") {
        return basePrice;
      }

      let bedFee = 0;
      if (property.is_linen_hire && !property.is_custom_price) {
        bedFee = (property.single_beds + property.double_beds) * 20;
      }
      
      let totalPrice = basePrice + bedFee;

      if (isOffPeak(booking)) {
        totalPrice *= 0.9;
      }

      return totalPrice;
    }

    let basePrice = durationHours * (booking_type.includes("with Materials") ? 45 : 40);
    let additionalCharge = 0;
    if (is_amenities) {
      additionalCharge += 30 * (property.bedrooms === 0 ? 1 : property.bedrooms);
    }

    return basePrice + additionalCharge;
  }, [isOffPeak]);

  const calculateTotal = useCallback((data) => {
    let total = 0;
    const updatedData = {};

    Object.entries(data).forEach(([clientId, client]) => {
      let clientTotal = 0;

      Object.entries(client.properties).forEach(([propertyId, property]) => {
        let propertyTotal = 0;

        property.bookings.forEach((booking) => {
          const price = calculateBookingPrice(booking, property.property_info);
          booking.price = parseFloat(price).toFixed(2); // Format for display
          if (!isNaN(price)) {
            propertyTotal += price;
            clientTotal += price;
            total += price;
          }
        });

        property.totalPrice = parseFloat(propertyTotal).toFixed(2); // Format for display
      });

      client.totalPrice = parseFloat(clientTotal).toFixed(2); // Format for display
      updatedData[clientId] = client;
    });

    setTotalSum(parseFloat(total).toFixed(2)); // Format for display
    setGroupedData(updatedData);
  }, [calculateBookingPrice]);

  // Function to fetch bookings data based on selected dates
  const fetchBookings = useCallback(async (start, end) => {
    if (!start || !end) return;

    try {
      const response = await axios.get(`/api/bookings/summary-report`, {
        params: { startDate: start, endDate: end },
      });
      calculateTotal(response.data);
      // Update URL to include the selected date range
      navigate(`?startDate=${start}&endDate=${end}`, { replace: true });
    } catch (error) {
      console.error("Error fetching bookings data:", error);
    }
  }, [calculateTotal, navigate]);


  // Initialize the date range from URL parameters if available
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const start = params.get("startDate");
    const end = params.get("endDate");

    if (start && end) {
      setStartDate(start);
      setEndDate(end);
      fetchBookings(start, end);
    }
  }, [location.search, fetchBookings]);


  return (
    <div className="summary-report-container">
      <h1 className="summary-report-title">Bookings Summary Report</h1>

      {/* Filter Section */}
      <div className="summary-report-filters">
        <div className="filter-group">
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={() => fetchBookings(startDate, endDate)} // Fetch data when input loses focus
          />
        </div>
        <div className="filter-group">
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={() => fetchBookings(startDate, endDate)} // Fetch data when input loses focus
          />
        </div>
        <button className="fetch-report-button" onClick={() => fetchBookings()}>
          Fetch Report
        </button>
      </div>

      {/* Content Section */}
      {Object.keys(groupedData).length > 0 ? (
        <div className="summary-report-content">
          {Object.entries(groupedData).map(([clientId, client]) => (
            <div key={clientId} className="summary-client-card">
              <h2 className="summary-client-name">{client.client_name}</h2>
              {Object.entries(client.properties).map(([propertyId, property]) => (
                <div key={propertyId} className="summary-property-card">
                  <h3 className="summary-property-title">
                    <a
                      style={{ textDecoration: "none" }}
                      href={`/properties/${propertyId}`}
                    >
                      {property.property_info.address_line_1} - Unit {property.property_info.unit_number} (
                      {property.property_info.bedrooms === 0 ? "S" : property.property_info.bedrooms},
                      {" "}
                      {property.property_info.bathrooms})
                    </a>
                  </h3>
                  {userRole !== "admin" && (
                    <p className="summary-property-total">
                      Property Total: {property.totalPrice} AED
                    </p>
                  )}
                  <ul className="summary-bookings-list">
                    {property.bookings.map((booking) => (
                      <a
                        href={`/bookings/${booking.booking_id}`}
                        className="summary-booking-link"
                        style={{ textDecoration: "none" }}
                        key={booking.booking_id}
                      >
                        <li className="summary-booking-item">
                          <div className="summary-booking-summary">
                            <div className="summary-booking-id">#{booking.booking_id} -&nbsp;</div>
                            <span className="summary-booking-time">
                              {new Date(booking.booking_start).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                              , {" "}
                              {(new Date(booking.booking_end) - new Date(booking.booking_start)) /
                                (1000 * 60 * 60)}{" "}
                              Hours
                            </span>
                          </div>
                          <div className="summary-booking-details">
                            <span>{booking.booking_type}</span>
                            {property.property_info.is_linen_hire ? <span>Linen Hire</span> : ''}
                            {booking.booking_type !== "All Inclusive Package" &&
                              booking.booking_type !== "All Inclusive Package + Deep Cleaning" && (
                                <>
                                  {booking.is_amenities ? <span>Amenities</span> : ''}
                                  {booking.is_laundry ? (
                                    <a href={`/laundry/${booking.booking_id}`}>
                                      Laundry
                                    </a>
                                  ) : ''}
                                </>
                              )}
                            {isOffPeak(booking) && <span className="off-peak-tag">Off Peak</span>}
                            <span>{booking.booking_status}</span>
                            <span
                              className={`summary-booking-price ${typeof booking.price === "number" ? "" : "no-price"}`}
                            >
                              Price: {typeof booking.price === "number" ? `${parseFloat(booking.price).toFixed(2)} AED` : booking.price}
                            </span>
                          </div>
                        </li>
                      </a>
                    ))}
                  </ul>
                </div>
              ))}
              {userRole !== "admin" && (
                <p className="summary-client-total">Client Total: {client.totalPrice} AED</p>
              )}
            </div>
          ))}
          {userRole !== "admin" && (
            <h2 className="summary-grand-total">Total for All Bookings: {totalSum} AED</h2>
          )}
        </div>
      ) : (
        <p className="summary-no-data">No bookings found for the selected date range.</p>
      )}
    </div>
  );
};

export default SummaryReport;