import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './login.css';  // Import the CSS file for styling

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { email, password });
      const { token, user } = response.data;
  
      // Store the token in a secure cookie
      document.cookie = `token=${token}; path=/; secure; sameSite=Strict`;
  
      // Store the user's first name and role in localStorage
      localStorage.setItem('firstName', user.first_name);
      localStorage.setItem('userRole', user.user_role);
      localStorage.setItem('userId', user.id);
  
      // Navigate to the home page, then reload to initialize components with updated data
      navigate('/');
      setTimeout(() => window.location.reload(), 0);  // Trigger a page reload after navigation
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Invalid email or password');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };  

  return (
    <div className="login-container">
      <div className="login-card">
      <img src="/logo.png" alt="Logo" class='login-logo'/>
        <h1>Login</h1>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <button type="submit" className="login-button">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <Link to="/forgot-password" className="reset-password-link">Forgot Password?</Link>
      </div>
    </div>
  );
}

export default Login;
