import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

function GeneralDetails({ property }) {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(property.google_maps_link);
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="details-card">
      <h2>General Details</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Client Display Name:</span>
          <span className="value">{property.client_display_name}</span>
        </div>
        {property.google_maps_link && (
          <>
            <div className="detail-item">
              <span className="label">Google Maps Link:</span>
            </div>
            <div className="google-maps-link-row">
              <a href={property.google_maps_link} target="_blank" rel="noopener noreferrer" className="google-maps-link">
                {property.google_maps_link}
              </a>
              <button className="icon-button" onClick={handleCopyLink}>
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GeneralDetails;
