import React from 'react';

function AdditionalInfo({ property }) {
  return (
    <div className="details-card">
      <h2>Additional Information</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Property Access:</span>
          <span className="value">{property.property_access}</span>
        </div>
        <div className="detail-item">
          <span className="label">WiFi Name:</span>
          <span className="value">{property.wifi_name}</span>
        </div>
        <div className="detail-item">
          <span className="label">WiFi Password:</span>
          <span className="value">{property.wifi_password}</span>
        </div>
      </div>
    </div>
  );
}

export default AdditionalInfo;
