import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import GeneralDetails from './PropertyDetailsComponents/GeneralDetails';
import AddressInformation from './PropertyDetailsComponents/AddressInformation';
import PropertyDetailsSection from './PropertyDetailsComponents/PropertyDetailsSection';
import AdditionalInfo from './PropertyDetailsComponents/AdditionalInfo';
import Notes from './PropertyDetailsComponents/Notes';
import ApartmentSetup from './PropertyDetailsComponents/ApartmentSetup';
import axios from 'axios';
import './propertyDetails.css';

function PropertyDetails() {
  const { propertyId } = useParams();
  const [property, setProperty] = useState(null);
  const [setupPhotos, setSetupPhotos] = useState([]);
  const navigate = useNavigate();

  // Fetch property details using Axios
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`/api/properties/${propertyId}`);
        setProperty(response.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    fetchProperty();
  }, [propertyId]);

  // Fetch setup photos using Axios
  useEffect(() => {
    const fetchSetupPhotos = async () => {
      try {
        const response = await axios.get(`/api/properties/${propertyId}/setup-photos`);
        setSetupPhotos(response.data);
      } catch (error) {
        console.error('Error fetching setup photos:', error);
      }
    };

    fetchSetupPhotos();
  }, [propertyId]);

  // Function to update notes in the state after a new note is added
  const updateNotes = (newNotes) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      dissapearing_note: newNotes.dissapearing_note,
      warehouse_note: newNotes.warehouse_note,
    }));
  };

  if (!property) {
    return <div>Loading...</div>;
  }

  // Format property information for display
  const bedroomDisplay = property.bedrooms === 0 ? 'S' : property.bedrooms;
  const propertyDisplay = `${property.client_display_name} - ${property.address_line_1} - Unit ${property.unit_number} (${bedroomDisplay} - ${property.bathrooms})`;

  return (
    <div className="property-details-wrapper">
      <div className="header">
        <div className="header-title">
          <h1>Property ID: #{propertyId.toString().padStart(6, '0')}</h1>
          <h2 className="unit-display">{propertyDisplay}</h2>
        </div>
        <button className="edit-button" onClick={() => navigate(`/properties/edit/${propertyId}`)}>
          Edit Property
        </button>
      </div>

      <div className="property-details-container">
        <GeneralDetails property={property} />
        <AddressInformation property={property} />
        <PropertyDetailsSection property={property} />
        <AdditionalInfo property={property} />
        <Notes property={property} propertyId={propertyId} updateNotes={updateNotes} />

        {/* Pass setupPhotos and propertyId to ApartmentSetup */}
        <ApartmentSetup setupPhotos={setupPhotos} propertyId={propertyId} />
      </div>
    </div>
  );
}

export default PropertyDetails;
