import React from 'react';

function AddressInformation({ property }) {
  return (
    <div className="details-card">
      <h2>Address Information</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Building Name:</span>
          <span className="value">{property.address_line_1}</span>
        </div>
        <div className="detail-item">
          <span className="label">Unit Number:</span>
          <span className="value">{property.unit_number}</span>
        </div>
        <div className="detail-item">
          <span className="label">Area:</span>
          <span className="value">{property.area}</span>
        </div>
      </div>
    </div>
  );
}

export default AddressInformation;
