import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './addBooking.css';
import axios from 'axios';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);

function AddBooking() {
  const [cleaners, setCleaners] = useState([]);
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [unavailableCleaners, setUnavailableCleaners] = useState([]);
  const [formData, setFormData] = useState({
    booking_start: null,
    client_id: '',
    property_id: '',
    type_id: '',
    status_id: '',
    booking_specific_notes: '',
    required_cleaners: 1,
    duration: 2,
    updated_by: 1,
    cleaner_ids: [],
    is_laundry: false,
    is_amenities: false, // New field for amenities toggle
  });

  const navigate = useNavigate();

  const amenitiesIncludedTypes = [1, 5];

  const isLaundryOrDelivery = formData.type_id === 6 || formData.type_id === 7 || formData.type_id === 8; // Adjust to your ID for "Delivery / Pickup"

  // Adjust these based on the specific IDs of "All Inclusive Package", "All Inclusive Package + Deep Cleaning", and "Laundry Only"
  const laundryRelatedTypes = [1, 5, 6]; // These are example IDs, adjust them to match your DB IDs

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cleanersRes, clientsRes, bookingTypesRes, bookingStatusRes] = await Promise.all([
          axios.get('/api/users/role/?role=cleaner'),
          axios.get('/api/clients'),
          axios.get('/api/bookings/booking_types'),
          axios.get('/api/bookings/booking_status'),
        ]);
        setCleaners(cleanersRes.data);
        setClients(clientsRes.data);
        setBookingTypes(bookingTypesRes.data);
        setBookingStatus(bookingStatusRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleClientChange = async (selectedOption) => {
    const clientId = selectedOption ? selectedOption.value : '';
    setFormData({ ...formData, client_id: clientId, property_id: '' });

    if (clientId) {
      try {
        const response = await axios.get(`/api/properties?clientId=${clientId}`);
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties for client:', error);
      }
    } else {
      setProperties([]);
    }
  };

  const handlePropertyChange = (selectedOption) => {
    if (selectedOption) {
      const selectedProperty = properties.find(property => property.id === selectedOption.value);
      const requiredCleaners = selectedProperty.required_cleaners || 1;
      const duration = calculateDuration(selectedProperty.bedrooms, requiredCleaners);
      const endTime = formData.booking_start ? calculateEndTime(formData.booking_start, duration) : null;

      setFormData({
        ...formData,
        property_id: selectedProperty.id,
        required_cleaners: requiredCleaners,
        duration,
        booking_end: endTime,
      });
    } else {
      setFormData({ ...formData, property_id: '', required_cleaners: 1, duration: 2 });
    }
  };

  const handleBookingTypeChange = (selectedOption) => {
    const typeId = selectedOption ? selectedOption.value : '';
    const isLaundryRelated = laundryRelatedTypes.includes(typeId);
    const isAmenitiesIncluded = amenitiesIncludedTypes.includes(typeId);

    setFormData({
      ...formData,
      type_id: typeId,
      required_cleaners: typeId === 6 || typeId === 7 || typeId === 8 ? 0 : Math.max(1, formData.required_cleaners),
      cleaner_ids: typeId === 6 || typeId === 7 || typeId === 8 ? [] : formData.cleaner_ids,
      duration: typeId === 6 || typeId === 7 || typeId === 8 ? 0 : formData.duration,
      is_laundry: isLaundryRelated,
      is_amenities: isAmenitiesIncluded, // Automatically set based on booking type
    });
  };

  const handleLaundryToggle = () => {
    setFormData({ ...formData, is_laundry: !formData.is_laundry });
  };

  // New function to handle amenities toggle
  const handleAmenitiesToggle = () => {
    setFormData({ ...formData, is_amenities: !formData.is_amenities });
  };

  const calculateDuration = (bedrooms, required_cleaners) => {
    let duration = 2;
    switch (bedrooms) {
      case 0: duration = 2.5; break;
      case 1: duration = 3; break;
      case 2: duration = required_cleaners === 2 ? 2.5 : 4; break;
      case 3: duration = 3; break;
      case 4: duration = 4; break;
      default: duration = 2;
    }
    return duration;
  };

  const calculateEndTime = (startTime, duration) => {
    if (!startTime) return null;
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(startDateTime.getTime() + duration * 60 * 60 * 1000);
    return endDateTime;
  };

  const handleDateChange = (date) => {
    const bookingStart = isLaundryOrDelivery ? new Date(date).setHours(18, 0, 0, 0) : date;
    const endTime = isLaundryOrDelivery ? bookingStart : calculateEndTime(date, formData.duration);
    setFormData({ ...formData, booking_start: bookingStart, booking_end: endTime });
  };

  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const fetchUnavailableCleaners = async () => {
      if (!formData.booking_start || !formData.booking_end) return;

      try {
        const response = await axios.get('/api/bookings/cleaners/availability', {
          params: {
            booking_start: formatDateToString(new Date(formData.booking_start)),
            booking_end: formatDateToString(new Date(formData.booking_end)),
          },
        });
        setUnavailableCleaners(response.data.map(cleaner => cleaner.cleaner_id));
      } catch (error) {
        console.error('Error fetching cleaner availability:', error);
      }
    };

    if (formData.booking_start && formData.booking_end) {
      fetchUnavailableCleaners();
    }
  }, [formData.booking_start, formData.booking_end]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.booking_start) {
      alert('Please select a booking start date and time.');
      return;
    }

    try {
      const bookingStartString = formatDateToString(new Date(formData.booking_start));
      const bookingEndString = formData.booking_end
        ? formatDateToString(new Date(formData.booking_end))
        : null;

      const submissionData = {
        ...formData,
        required_cleaners: isLaundryOrDelivery ? 0 : formData.required_cleaners,
        cleaner_ids: formData.cleaner_ids.filter(id => id),
        booking_start: bookingStartString,
        booking_end: bookingEndString,
      };

      await axios.post('/api/bookings', submissionData);
      alert('Booking created successfully!');
      navigate('/bookings');
    } catch (error) {
      console.error('Error creating booking:', error);
      alert('Error creating booking.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : '' });
  };

  const handleRequiredCleanersChange = (e) => {
    const required_cleaners = parseInt(e.target.value, 10);
    const duration = calculateDuration(properties.find(property => property.id === formData.property_id)?.bedrooms, required_cleaners);
    const endTime = formData.booking_start ? calculateEndTime(formData.booking_start, duration) : null;

    setFormData({ ...formData, required_cleaners, duration, booking_end: endTime });
  };

  const handleCleanerChange = (selectedOption, index) => {
    const newCleanerIds = [...formData.cleaner_ids];
    newCleanerIds[index] = selectedOption ? selectedOption.value : null;
    setFormData({ ...formData, cleaner_ids: newCleanerIds });
  };

  const handleDurationChange = (selectedOption) => {
    const duration = parseFloat(selectedOption.value);
    const endTime = formData.booking_start ? calculateEndTime(formData.booking_start, duration) : null;
    setFormData({ ...formData, duration, booking_end: endTime });
  };

  const durationOptions = [
    { value: '2', label: '2 Hours' },
    { value: '2.5', label: '2.5 Hours' },
    { value: '3', label: '3 Hours' },
    { value: '3.5', label: '3.5 Hours' },
    { value: '4', label: '4 Hours' },
    { value: '4.5', label: '4.5 Hours' },
    { value: '5', label: '5 Hours' },
    { value: '5.5', label: '5.5 Hours' },
    { value: '6', label: '6 Hours' },
    { value: '6.5', label: '6.5 Hours' },
    { value: '7', label: '7 Hours' },
    { value: '7.5', label: '7.5 Hours' },
    { value: '8', label: '8 Hours' },
  ];

  const cleanerSelects = [];
  for (let i = 0; i < formData.required_cleaners; i++) {
    cleanerSelects.push(
      <div key={i} className="form-group">
        <label>Cleaner {i + 1}</label>
        <Select
          options={cleaners
            .map((cleaner) => ({
              value: cleaner.id,
              label: `${cleaner.first_name} ${cleaner.last_name} ${unavailableCleaners.includes(cleaner.id) ? '(Unavailable)' : ''}`,
              isDisabled: unavailableCleaners.includes(cleaner.id),
            }))}
          onChange={(selectedOption) => handleCleanerChange(selectedOption, i)}
          placeholder={`Select Cleaner ${i + 1}`}
          isClearable
        />
      </div>
    );
  }

  return (
    <div className="add-booking-container">
      <h2>Add New Booking</h2>
      <form className="new-booking-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group longer">
            <label>Booking Type</label>
            <Select
              options={bookingTypes.map((type) => ({
                value: type.id,
                label: type.type,
              }))}
              onChange={handleBookingTypeChange}
              placeholder="Select a booking type"
              required
            />
          </div>
          <div className="form-group longer">
            <label>Booking Status</label>
            <Select
              options={bookingStatus.map((status) => ({
                value: status.id,
                label: status.status_name,
              }))}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'status_id')}
              placeholder="Select booking status"
              required
            />
          </div>
          <div className="form-group laundry shorter">
            <label>Is Laundry?</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={formData.is_laundry}
                onChange={handleLaundryToggle}
                disabled={false} // Allow editing even if the type is automatically set
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="form-group amenities shorter">
          <label>Is Amenities?</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={formData.is_amenities}
              onChange={handleAmenitiesToggle}
              disabled={amenitiesIncludedTypes.includes(formData.type_id)} // Disable toggle if type auto-sets amenities
            />
            <span className="slider round"></span>
          </label>
        </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Client</label>
            <Select
              options={clients.map((client) => ({
                value: client.id,
                label: client.display_name || `${client.first_name} ${client.last_name}`,
              }))}
              onChange={handleClientChange}
              placeholder="Select a client"
              required
            />
          </div>
          <div className="form-group">
            <label>Property</label>
            <Select
              options={properties.map((property) => ({
                value: property.id,
                label: `${property.unit_number} - ${property.address_line_1} (${property.bedrooms === 0 ? 'S' : property.bedrooms}, ${property.bathrooms})`,
              }))}
              onChange={handlePropertyChange}
              placeholder="Select a property"
              isDisabled={!formData.client_id}
              required
            />
          </div>
        </div>

        <div className="form-row">
          {!isLaundryOrDelivery && (
            <div className="form-group">
              <label>Required Cleaners</label>
              <input
                type="number"
                name="required_cleaners"
                value={formData.required_cleaners}
                onChange={handleRequiredCleanersChange}
                min="1"
                required
              />
            </div>
          )}
          <div className="form-group">
            <label>Start Time</label>
            <DatePicker
              selected={formData.booking_start}
              onChange={handleDateChange}
              showTimeSelect={!isLaundryOrDelivery}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="Time"
              locale="en-GB"
              dateFormat={isLaundryOrDelivery ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
              placeholderText="Select Start Time"
              required
            />
          </div>
          {!isLaundryOrDelivery && (
            <div className="form-group">
              <label>Duration</label>
              <Select
                options={durationOptions}
                value={durationOptions.find(option => option.value === String(formData.duration))}
                onChange={handleDurationChange}
                placeholder="Select a duration"
                required
              />
            </div>
          )}
        </div>
        {!isLaundryOrDelivery && <div className="form-row">{cleanerSelects}</div>}
        <div className="form-group">
          <label>Booking Notes</label>
          <textarea
            name="booking_specific_notes"
            value={formData.booking_specific_notes}
            onChange={handleChange}
            placeholder="Add any specific notes for this booking"
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Submit Booking</button>
      </form>
    </div>
  );
}

export default AddBooking;