import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

function ImageManager({
    userRole,
    beforePhotos,
    stainPhotos,
    damagePhotos,
    packingPhotos,
    isEditMode,
    handleFileSelect,
    handleFileUpload,
    handleDeletePhoto,
    toggleEditMode,
    selectedFiles,
    isUploading,
    setUploadType,
    uploadType,
    selectedStainItem,
    setSelectedFiles,
    setSelectedStainItem,
    laundryItems,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index, type) => {
        // Determine the actual type for correct handling of stain and damage in the modal
        const actualType = type === 'stained-damaged'
            ? (stainPhotos.includes(stainPhotos[index]) ? 'stain' : 'damage')
            : type;
        setUploadType(actualType);
        setCurrentIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const getPhotosByType = (type) => {
        switch (type) {
            case 'before':
                return beforePhotos;
            case 'stain':
                return stainPhotos;
            case 'damage':
                return damagePhotos;
            case 'packing':
                return packingPhotos;
            default:
                return [];
        }
    };

    // Combine stain and damage photos in one section
    const imagesConfig = {
        before: beforePhotos,
        'stained-damaged': [...stainPhotos, ...damagePhotos],
        packing: packingPhotos,
    };

    return (
        <div className="image-manager">
            {Object.entries(imagesConfig).map(([type, photos]) => (
                photos.length > 0 && (
                    <div key={type} className="photos-section">
                        <h3>
                            {type === 'stained-damaged' ? 'Stained / Damaged' : type.charAt(0).toUpperCase() + type.slice(1) + ' Photos'}
                        </h3>
                        <div className="photos-grid">
                            {photos.map((photo, index) => (
                                <div key={photo.id || index} className="photo-item">
                                    <img
                                        src={photo.image_url}
                                        alt={`${type} ${index}`}
                                        className="thumbnail"
                                        onClick={() => openModal(index, type)}
                                    />
                                    {(userRole === 'admin' || userRole === 'manager') && isEditMode && (
                                        <button
                                            className="delete-photo-button"
                                            onClick={() => handleDeletePhoto(photo.id, type)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    )}
                                    {/* Badge for item name with conditional color based on type */}
                                    {photo.item_name && (
                                        <span className={`photo-badge ${photo.type === 'stain' ? 'stain' : 'damage'}`}>
                                            {photo.item_name}
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )
            ))}

            {isOpen && (
                <div className="photo-modal-overlay" onClick={closeModal}>
                    <div className="photo-modal" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={closeModal}>
                            &times;
                        </button>
                        <ImageGallery
                            items={getPhotosByType(uploadType).map((photo) => ({
                                original: photo.image_url,
                                thumbnail: photo.image_url,
                            }))}
                            startIndex={currentIndex}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            slideDuration={400}
                            showNav={true}
                        />
                    </div>
                </div>
            )}

            {(userRole === 'admin' || userRole === 'manager') && (
                <div className="edit-photos-section">
                    <button className="add-note-button edit-photos-button" onClick={toggleEditMode}>
                        {isEditMode ? (
                            <>
                                <FontAwesomeIcon icon={faSave} /> Save
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faEdit} /> Edit Photos
                            </>
                        )}
                    </button>

                    {isEditMode && (
                        <div className="upload-area">
                            <select
                                value={uploadType}
                                onChange={(e) => setUploadType(e.target.value)}
                                className="upload-type-select"
                            >
                                <option value="before">Before</option>
                                <option value="stain">Stain</option>
                                <option value="damage">Damage</option>
                                <option value="packing">Packing</option>
                            </select>
                            {(uploadType === 'stain' || uploadType === 'damage') && (
                                <select
                                    value={selectedStainItem}
                                    onChange={(e) => setSelectedStainItem(e.target.value)}
                                    className="affected-item-select"
                                >
                                    <option value="">Select Affected Item</option>
                                    {laundryItems.map((item) => (
                                        <option key={item.laundry_item_id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <input
                                id="upload-input"
                                type="file"
                                multiple
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                            />
                            <button
                                className="upload-button"
                                onClick={() => document.getElementById('upload-input').click()}
                            >
                                <FontAwesomeIcon icon={faUpload} /> Upload Photos
                            </button>

                            <div className="photo-preview-container">
                                {selectedFiles.map((file, index) => (
                                    <div key={index} className="photo-preview">
                                        <img src={file.preview} alt={`Preview ${index}`} />
                                        <button type="button" onClick={() => setSelectedFiles((prev) => prev.filter((_, i) => i !== index))}>X</button>
                                    </div>
                                ))}
                            </div>

                            {selectedFiles.length > 0 && (
                                <button className="confirm-upload-apartment" onClick={handleFileUpload} disabled={isUploading}>
                                    {isUploading ? 'Uploading...' : 'Confirm Upload'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ImageManager;
