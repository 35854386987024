import React from "react";

function LoadingPopup({ isLoading, feedbackMessage, onClose }) {
  return (
    (isLoading || feedbackMessage) && (
      <div className="loading-overlay">
        <div className="loading-box">
          {isLoading ? (
            <>
              <div className="spinner"></div>
              <p>Loading...</p>
            </>
          ) : (
            <>
              <p>{feedbackMessage}</p>
              <button className="ok-button" onClick={onClose}>
                OK
              </button>
            </>
          )}
        </div>
      </div>
    )
  );
}

export default LoadingPopup;
