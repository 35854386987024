import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';  // Import axios for API requests
import './clients.css';

function Clients() {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true); // Loading state to indicate when data is being fetched
    const [error, setError] = useState(null); // Error state to handle errors
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get('/api/clients');
                if (response.status === 200) {
                    const sortedClients = response.data.sort((a, b) => a.id - b.id);
                    setClients(sortedClients);
                    setLoading(false); // Data fetched, stop loading
                } else {
                    throw new Error('Error fetching clients');
                }
            } catch (error) {
                setError('Error fetching clients. Please try again later.');
                setLoading(false); // Stop loading when an error occurs
            }
        };

        fetchClients();
    }, []);

    const filteredClients = clients.filter(client =>
        `${client.display_name} ${client.id}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const formatId = (id) => {
        return id.toString().padStart(5, '0');
    };

    // Handle row click to navigate to client details page
    const handleRowClick = (clientId) => {
        navigate(`/clients/${clientId}`);
    };

    return (
        <div className="clients-container">
            <h2>Clients</h2>

            {/* Search bar and Add New Client in the same row */}
            <div className="clients-header">
                <input
                    type="text"
                    placeholder="Search clients..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="search-bar"
                /><Link to="/clients/new">
                    <button className="add-client-button">+ Add New Client</button>
                </Link>
            </div>

            {/* Show loading message */}
            {loading && <p>Loading clients...</p>}

            {/* Show error message */}
            {error && <p className="error-message">{error}</p>}

            {!loading && !error && (
                <table className="clients-table">
                    <thead>
                        <tr>
                            <th>Client ID</th>
                            <th>Display Name</th>
                            <th>Email</th>
                            <th style={{ textAlign: 'center' }}>Properties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.map(client => (
                            <tr
                                key={client.id}
                                className="client-row"
                                onClick={() => handleRowClick(client.id)} // Navigate to client details page on row click
                                style={{ cursor: 'pointer' }}
                            >
                                <td>#{formatId(client.id)}</td>
                                <td>{client.display_name}</td>
                                <td>{client.email}</td>
                                <td style={{ textAlign: 'center' }}>{client.property_count || 0}</td> {/* Display property count */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Clients;
