import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './properties.css';

function Properties() {
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Fetch properties from the backend using Axios and sort by Property ID
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get('/api/properties');
        const sortedProperties = response.data.sort((a, b) => a.id - b.id); // Sort by Property ID in ascending order
        setProperties(sortedProperties);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  // Format property ID as #000007
  const formatPropertyId = (id) => `#${id.toString().padStart(6, '0')}`;

  // Filter properties by client display name, building name, unit number, or property ID
  const filteredProperties = Array.isArray(properties)
    ? properties.filter(property => {
        const clientDisplayName = property.client_display_name || '';
        const buildingName = property.address_line_1 || '';
        const unitNumber = property.unit_number || '';
        const propertyIdFormatted = formatPropertyId(property.id);
        const propertyId = property.id ? property.id.toString() : '';
        
        return (
          clientDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          buildingName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          propertyId.includes(searchTerm) ||
          propertyIdFormatted.includes(`#${searchTerm.padStart(6, '0')}`)
        );
      })
    : [];

  // Handle row click to navigate to the property details page
  const handleRowClick = (propertyId) => {
    navigate(`/properties/${propertyId}`);
  };

  return (
    <div className="properties-container">
      <h2>Properties</h2>

      <div className="search-and-add-container">
        <input
          type="text"
          placeholder="Search by Client Name, Building Name, Unit Number, or Property ID..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <Link to={`/properties/new/`}>
          <button className="add-property-button">+ Add New Property</button>
        </Link>
      </div>

      <table className="properties-table">
        <thead>
          <tr>
            <th>Property ID</th>
            <th>Client Display Name</th>
            <th>Building Name</th>
            <th style={{ textAlign: 'center' }}>Unit Number</th>
            <th style={{ textAlign: 'center' }}>Bedrooms</th>
            <th style={{ textAlign: 'center' }}>Area</th>
          </tr>
        </thead>
        <tbody>
          {filteredProperties.map(property => (
            <tr key={property.id} onClick={() => handleRowClick(property.id)} className="property-row" style={{ cursor: 'pointer' }}>
              <td>{formatPropertyId(property.id)}</td>
              <td>{property.client_display_name}</td>
              <td>{property.address_line_1}</td>
              <td style={{ textAlign: 'center' }}>{property.unit_number}</td>
              <td style={{ textAlign: 'center' }}>{property.bedrooms}</td>
              <td style={{ textAlign: 'center' }}>{property.area}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Properties;
