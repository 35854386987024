import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import './addClient.css'; // Reusing addClient.css

function EditClient() {
  const { clientId } = useParams(); // Get clientId from the route
  const [client, setClient] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    display_name: '',
    email: '',
    contact_number: '',
    all_properties_notes: '',
    trn_number: '',
    address_line_1: '',
    floor_number: '',
    unit_number: '',
    city: '',
    state: '',
    country: '',
  }); // Initialize client with default empty values
  const [isDirty, setIsDirty] = useState(false); // Track if form is edited
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the current client data
    const fetchClient = async () => {
      try {
        const response = await axios.get(`/api/clients/${clientId}`);
        setClient(response.data); // Set the fetched client data
        setLoading(false); // Stop loading
      } catch (error) {
        setError('Error fetching client data');
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // Handle input changes
  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
    setIsDirty(true); // Set dirty flag
  };

  // Handle save client changes
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`/api/clients/${clientId}`, client);
      if (response.status === 200) {
        setIsDirty(false);
        // Navigate to the Client Details page after saving
        navigate(`/clients/${clientId}`);
      } else {
        setError('Error saving client data');
      }
    } catch (error) {
      setError('Error saving client');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="add-client-container">
      <h2>Edit Client</h2>
      <form className="new-client-form" onSubmit={handleSave}>
        <h3>Personal Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={client.first_name || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              value={client.last_name || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              name="company_name"
              value={client.company_name || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Display Name</label>
            <input
              type="text"
              name="display_name"
              value={client.display_name || ''} // Ensure field is controlled
              onChange={handleChange}
              required
              minLength={4}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={client.email || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              value={client.contact_number || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
        </div>
        <h3>Notes for all properties</h3>
        <div className="form-row">
          <div className="form-group full-width">
            <label>Property Notes</label>
            <textarea
              name="all_properties_notes"
              value={client.all_properties_notes || ''} // Ensure field is controlled
              onChange={handleChange}
              rows="5"
            />
          </div>
        </div>
        <h3>Billing Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>TRN Number</label>
            <input
              type="text"
              name="trn_number"
              value={client.trn_number || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Address Line 1</label>
            <input
              type="text"
              name="address_line_1"
              value={client.address_line_1 || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Floor</label>
            <input
              type="text"
              name="floor_number"
              value={client.floor_number || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Apartment / Office / Unit Number</label>
            <input
              type="text"
              name="unit_number"
              value={client.unit_number || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={client.city || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Emirate</label>
            <input
              type="text"
              name="state"
              value={client.state || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Country</label>
            <input
              type="text"
              name="country"
              value={client.country || ''} // Ensure field is controlled
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="save-button">Save</button>
        </div>
      </form>
    </div>
  );
}

export default EditClient;
