import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import './addTeamMember.css';

function EditTeamMember() {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        eid: '',
        created_at: '',
    });
    const [roleOptions, setRoleOptions] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();

    // Retrieve user role from localStorage
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const fetchTeamMember = async () => {
            try {
                const response = await axios.get(`/api/team/${id}`);
                const teamMember = response.data;

                setFormData({
                    first_name: teamMember.first_name,
                    last_name: teamMember.last_name,
                    email: teamMember.email,
                    role: teamMember.role,
                    eid: teamMember.eid || '',
                    created_at: teamMember.created_at || '',
                });
            } catch (error) {
                console.error('Error fetching team member details:', error);
                setError('Error fetching team member details.');
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axios.get('/api/team/roles');
                const roles = response.data;

                const filteredRoles = roles
                    .filter(role => {
                        // Exclude "client" role and only allow "manager" role for managers
                        if (role.role_name.toLowerCase() === 'client') return false;
                        if (role.role_name.toLowerCase() === 'manager' && userRole !== 'manager') return false;
                        return true;
                    })
                    .map(role => ({
                        value: role.id,
                        label: role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1),
                    }));
                setRoleOptions(filteredRoles);
            } catch (error) {
                console.error('Error fetching roles:', error);
                setError('Error fetching roles.');
            }
        };

        fetchTeamMember();
        fetchRoles();
    }, [id, userRole]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };    

    const handleRoleChange = (selectedOption) => {
        setFormData({ ...formData, role: selectedOption.value });
    };

    const handleUpdateMember = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.put(`/api/team/${id}`, formData);

            if (response.status === 200) {
                navigate('/team');
            } else {
                setError('Error updating team member. Please try again.');
            }
        } catch (error) {
            console.error('Error updating team member:', error);
            setError('Something went wrong. Please try again.');
        }

        setLoading(false);
    };

    const handleDeleteMember = async () => {
        const confirmed = window.confirm('Are you sure you want to delete this team member? This action cannot be undone.');

        if (confirmed) {
            setDeleting(true);
            try {
                const response = await axios.delete(`/api/team/${id}`);
                if (response.status === 200) {
                    navigate('/team');
                } else {
                    setError('Error deleting team member.');
                }
            } catch (error) {
                console.error('Error deleting team member:', error);
                setError('Something went wrong during deletion.');
            }
            setDeleting(false);
        }
    };

    return (
        <div className="add-team-member-container">
            <h2>Edit Team Member</h2>
            <form className="add-team-member-form" onSubmit={handleUpdateMember}>
                {error && <p className="error">{error}</p>}

                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Role</label>
                    <Select
                        options={roleOptions}
                        onChange={handleRoleChange}
                        value={roleOptions.find(option => option.value === formData.role)}
                        placeholder="Select Role"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Emirates ID (Optional)</label>
                    <input
                        type="text"
                        name="eid"
                        value={formData.eid}
                        onChange={handleInputChange}
                        placeholder="Enter Emirates ID"
                    />
                </div>

                <div className="form-group">
                    <label>Date of Join</label>
                    <input
                        type="date"
                        name="created_at"
                        value={formData.created_at.split('T')[0]}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    {userRole === 'manager' && (
                        <>
                            <label>Password (Optional)</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password || ''}
                                onChange={handleInputChange}
                                placeholder="Enter new password (leave blank to keep existing)"
                            />
                        </>
                    )}
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Updating...' : 'Update Team Member'}
                </button>

                {/* Render delete button only for manager */}
                {userRole === 'manager' && (
                    <button
                        type="button"
                        className="team-delete-button"
                        onClick={handleDeleteMember}
                        disabled={deleting}
                    >
                        {deleting ? 'Deleting...' : 'Delete Team Member'}
                    </button>
                )}
            </form>
        </div>
    );
}

export default EditTeamMember;
