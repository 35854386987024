import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faCheck, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import './drivers.css';

const Drivers = () => {
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [newExpense, setNewExpense] = useState({ amount: '', description: '' });
  const [newDeposit, setNewDeposit] = useState({ amount: '' });
  const [receiptFile, setReceiptFile] = useState(null);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const userRole = localStorage.getItem('userRole');
  const userId = localStorage.getItem('userId');

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const fetchDrivers = useCallback(async () => {
    try {
      if (userRole === 'driver') {
        const [transactionsResponse, balanceResponse] = await Promise.all([
          axios.get(`/api/drivers/${userId}/transactions`),
          axios.get(`/api/drivers/${userId}/balance`)
        ]);

        const driverData = {
          id: userId,
          name: 'Your Account',
          balance: balanceResponse.data.balance || '0.00',
          transactions: transactionsResponse.data || []
        };
        setDrivers([driverData]);
        setSelectedDriver(driverData);
      } else {
        const response = await axios.get('/api/drivers');
        setDrivers(response.data || []);
      }
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  }, [userRole, userId]);

  const fetchDriverTransactions = async (driverId) => {
    try {
      const [transactionsResponse, balanceResponse] = await Promise.all([
        axios.get(`/api/drivers/${driverId}/transactions`),
        axios.get(`/api/drivers/${driverId}/balance`)
      ]);

      const updatedDrivers = drivers.map(driver =>
        driver.id === driverId ? { ...driver, balance: balanceResponse.data.balance || '0.00' } : driver
      );
      setDrivers(updatedDrivers);

      const driver = updatedDrivers.find(d => d.id === driverId);
      setSelectedDriver({
        ...driver,
        balance: balanceResponse.data.balance || '0.00',
        transactions: transactionsResponse.data || [],
      });
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleAddExpense = async () => {
    const { amount, description } = newExpense;
    if (!amount || !description) {
      setFeedbackMessage("Please enter both amount and description for the expense.");
      return;
    }

    const formData = new FormData();
    formData.append('amount', -Math.abs(amount));
    formData.append('description', description);
    formData.append('transaction_type', 'expense');
    if (receiptFile) formData.append('receipt', receiptFile);

    try {
      setLoading(true); // Show loading spinner
      await axios.post(`/api/attachments/drivers/${selectedDriver.id}/transactions`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      await fetchDriverTransactions(selectedDriver.id);
      setNewExpense({ amount: '', description: '' });
      setReceiptFile(null);
      setFeedbackMessage("Expense added successfully.");
    } catch (error) {
      console.error('Error adding expense:', error);
      setFeedbackMessage("Failed to add expense. Please try again.");
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleAddDeposit = async () => {
    if (userRole === 'driver') return;
    const { amount } = newDeposit;
    if (!amount) return alert("Please enter an amount for the deposit");

    try {
      await axios.post(`/api/attachments/drivers/${selectedDriver.id}/transactions`, {
        amount: Math.abs(amount),
        description: 'Deposit',
        transaction_type: 'deposit',
      });
      fetchDriverTransactions(selectedDriver.id);
      setNewDeposit({ amount: '' });
    } catch (error) {
      console.error('Error adding deposit:', error);
    }
  };

  const handleDeleteTransaction = async (transactionId) => {
    const confirmation = window.confirm("Are you sure you want to delete this transaction?");
    if (!confirmation) return;

    try {
      await axios.delete(`/api/attachments/drivers/transactions/${transactionId}`);
      fetchDriverTransactions(selectedDriver.id);
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };

  const handleApproveTransaction = async (transactionId) => {
    try {
      await axios.put(`/api/drivers/transactions/${transactionId}/approve`);
      fetchDriverTransactions(selectedDriver.id);
    } catch (error) {
      console.error('Error approving transaction:', error);
    }
  };

  const handleEditTransaction = (transaction) => {
    if (userRole === 'driver' && transaction.transaction_type !== 'expense') return;
    setEditingTransaction(transaction);
  };

  const handleSaveEditTransaction = async () => {
    let { amount, description } = editingTransaction;
    if (editingTransaction.transaction_type === 'expense') {
      amount = -Math.abs(amount);
    } else if (editingTransaction.transaction_type === 'deposit') {
      amount = Math.abs(amount);
    }
    try {
      await axios.put(`/api/drivers/transactions/${editingTransaction.id}`, {
        amount,
        description,
        transaction_type: editingTransaction.transaction_type,
      });
      fetchDriverTransactions(selectedDriver.id);
      setEditingTransaction(null);
    } catch (error) {
      console.error('Error saving transaction:', error);
    }
  };

  const cancelEditing = useCallback(() => {
    setEditingTransaction(null);
  }, []);

  useEffect(() => {
    fetchDrivers();
  }, [fetchDrivers]);

  return (
    <div className="drivers-container">
      {(loading || feedbackMessage) && (
        <div className="loading-overlay">
          <div className="loading-box">
            {loading ? (
              <>
                <div className="spinner"></div>
                <p>Loading...</p>
              </>
            ) : (
              <>
                <p>{feedbackMessage}</p>
                <button className="ok-button" onClick={() => setFeedbackMessage('')}>
                  OK
                </button>
              </>
            )}
          </div>
        </div>
      )}
      <h2 className="title">Driver Expenses & Deposits</h2>
      <div className="content">
        {userRole !== 'driver' && (
          <div className="drivers-list">
            {drivers.map(driver => (
              <div
                key={driver.id}
                className={`driver-card ${selectedDriver?.id === driver.id ? 'active' : ''}`}
                onClick={() => fetchDriverTransactions(driver.id)}
              >
                <p className="driver-name">{driver.name}</p>
                <p className="driver-balance">Balance: <strong>AED {driver.balance || '0.00'}</strong></p>
              </div>
            ))}
          </div>
        )}
        {selectedDriver && (
          <div className="driver-details">
            <h3>{userRole === 'driver' ? 'Your Account' : `${selectedDriver.name}'s Account`}</h3>
            <p className={`current-balance ${parseFloat(selectedDriver.balance) < 0 ? 'negative' : 'positive'}`}>Current Balance: <strong>AED {selectedDriver.balance || '0.00'}</strong></p>

            <div className="transaction-form">
              <input
                type="text"
                placeholder="Expense Description"
                value={newExpense.description}
                onChange={(e) => setNewExpense({ ...newExpense, description: e.target.value })}
              />
              <input
                type="number"
                placeholder="Expense Amount"
                value={newExpense.amount}
                onChange={(e) => setNewExpense({ ...newExpense, amount: e.target.value })}
              />
              <input
                type="file"
                onChange={(e) => setReceiptFile(e.target.files[0])}
              />
              <button
                onClick={handleAddExpense}
                className="add-button expense"
              >
                Add Expense
              </button>

              {userRole !== 'driver' && (
                <>
                  <input
                    type="number"
                    placeholder="Deposit Amount"
                    value={newDeposit.amount}
                    onChange={(e) => setNewDeposit({ amount: e.target.value })}
                  />
                  <button onClick={handleAddDeposit} className="add-button deposit">Add Deposit</button>
                </>
              )}
            </div>

            <div className="transactions">
              <h4>Transaction History</h4>
              {selectedDriver.transactions.map((transaction) => (
                <div
                  key={transaction.id}
                  className={`transaction-item ${!transaction.is_approved
                    ? 'unapproved'
                    : transaction.transaction_type === 'deposit'
                      ? 'deposit'
                      : transaction.transaction_type === 'expense'
                        ? 'expense'
                        : ''
                    }`}
                >
                  {editingTransaction?.id === transaction.id ? (
                    <div className="edit-form">
                      <input
                        type="text"
                        value={editingTransaction.description}
                        onChange={(e) =>
                          setEditingTransaction({
                            ...editingTransaction,
                            description: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        value={editingTransaction.amount}
                        onChange={(e) =>
                          setEditingTransaction({
                            ...editingTransaction,
                            amount: e.target.value,
                          })
                        }
                      />
                      <button onClick={handleSaveEditTransaction}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                      <button onClick={cancelEditing}>Cancel</button>
                    </div>
                  ) : (
                    <>
                      <div className="date-description">
                        <p>{transaction.description}</p>
                        <p className="transaction-date">
                          {formatDate(transaction.created_at)}
                        </p>
                      </div>
                      <div className="main-info">
                        <p>
                          <strong>AED {transaction.amount}</strong>
                        </p>
                        {!transaction.is_approved && (
                          <span className="pending-status">Pending</span>
                        )}
                      </div>
                      <div className="transaction-actions">
                        {transaction.receipt_url && (
                          <button
                            className="receipt-button"
                            onClick={() => window.open(transaction.receipt_url, "_blank", "noopener noreferrer")}
                          >
                            <FontAwesomeIcon icon={faFileInvoice} />
                          </button>
                        )}
                        {userRole !== 'driver' && (
                          <>
                            {!transaction.is_approved && (
                              <button
                                onClick={() => handleApproveTransaction(transaction.id)}
                                className="approve-button"
                              >
                                <FontAwesomeIcon icon={faCheck} />
                              </button>
                            )}
                            <button
                              onClick={() => handleEditTransaction(transaction)}
                              className="edit-button"
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button
                              onClick={() => handleDeleteTransaction(transaction.id)}
                              className="delete-button"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </>
                        )}
                        {userRole === 'driver' && transaction.transaction_type === 'expense' && (
                          <>
                            <button
                              onClick={() => handleEditTransaction(transaction)}
                              className="edit-button"
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button
                              onClick={() => handleDeleteTransaction(transaction.id)}
                              className="delete-button"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Drivers;
