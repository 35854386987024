import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios'; // Import axios

function Notes({ property, propertyId, updateNotes }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenWarehouse, setModalIsOpenWarehouse] = useState(false);
  const [disappearingNote, setDisappearingNote] = useState(property.dissapearing_note || '');
  const [warehouseNote, setWarehouseNote] = useState(property.warehouse_note || '');

  const handleAddNote = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/properties/${propertyId}/note`, {
        dissapearing_note: disappearingNote || null,
      });
      if (response.status === 200) {
        const updatedProperty = response.data;
        setModalIsOpen(false);
        // Update the parent component with the new notes
        updateNotes({
          dissapearing_note: updatedProperty.dissapearing_note,
          warehouse_note: property.warehouse_note,
        });
      }
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleAddWarehouseNote = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/properties/${propertyId}/warehouse-note`, {
        warehouse_note: warehouseNote || null,
      });
      if (response.status === 200) {
        const updatedProperty = response.data;
        setModalIsOpenWarehouse(false);
        // Update the parent component with the new warehouse note
        updateNotes({
          dissapearing_note: property.dissapearing_note,
          warehouse_note: updatedProperty.warehouse_note,
        });
      }
    } catch (error) {
      console.error('Error adding warehouse note:', error);
    }
  };

  return (
    <div className="details-card notes-section full-width">
      <h2>Notes</h2>
      <div className="notes-row">
        <div className="note-item">
          <span className="label">Permanent Notes:</span>
          <span className="value">{property.permanent_notes}</span>
        </div>
        <div className="note-item">
          <span className="label">Notes for next cleaning:</span>
          <span className="value">{property.dissapearing_note}</span>
        </div>
        <div className="note-item">
          <span className="label">Warehouse note:</span>
          <span className="value">{property.warehouse_note}</span>
        </div>
        <button className="add-note-button" onClick={() => setModalIsOpen(true)}>
          + Add Note for Next Cleaning
        </button>
        <button className="add-note-button" onClick={() => setModalIsOpenWarehouse(true)}>
          + Add Warehouse Note
        </button>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpen(false)}>&times;</button>
        <h2>Add Note for Next Cleaning</h2>
        <form onSubmit={handleAddNote}>
          <textarea value={disappearingNote} onChange={(e) => setDisappearingNote(e.target.value)} placeholder="Enter note for next cleaning" />
          <button type="submit">Submit</button>
        </form>
      </Modal>

      <Modal isOpen={modalIsOpenWarehouse} onRequestClose={() => setModalIsOpenWarehouse(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpenWarehouse(false)}>&times;</button>
        <h2>Add Warehouse Note</h2>
        <form onSubmit={handleAddWarehouseNote}>
          <textarea value={warehouseNote} onChange={(e) => setWarehouseNote(e.target.value)} placeholder="Enter warehouse note" />
          <button type="submit">Submit</button>
        </form>
      </Modal>
    </div>
  );
}

export default Notes;
