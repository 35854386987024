import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './addLaundry.css';

function AddLaundry() {
    const navigate = useNavigate();
    const [todayBookings, setTodayBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState('');
    const [dropOffItems, setDropOffItems] = useState([
        { name: 'Bedsheet', quantity: '' },
        { name: 'Duvet Cover', quantity: '' },
        { name: 'Pillowcase', quantity: '' },
        { name: 'Bath Towel', quantity: '' },
        { name: 'Hand Towel', quantity: '' },
        { name: 'Face Towel', quantity: '' },
        { name: 'Bathmat', quantity: '' }
    ]);
    const [pickupItems, setPickupItems] = useState([
        { name: 'Bedsheet', quantity: '' },
        { name: 'Duvet Cover', quantity: '' },
        { name: 'Pillowcase', quantity: '' },
        { name: 'Bath Towel', quantity: '' },
        { name: 'Hand Towel', quantity: '' },
        { name: 'Face Towel', quantity: '' },
        { name: 'Bathmat', quantity: '' }
    ]);

    // Extra items for Drop Off and Pickup
    const [dropOffExtraItems, setDropOffExtraItems] = useState([]);
    const [pickupExtraItems, setPickupExtraItems] = useState([]);
    const [selectedExtraDropOffItem, setSelectedExtraDropOffItem] = useState('');
    const [selectedExtraPickupItem, setSelectedExtraPickupItem] = useState('');
    const [comment, setComment] = useState('');
    const [extraItemOptions, setExtraItemOptions] = useState([]);

    useEffect(() => {
        const fetchTodayBookings = async () => {
            try {
                const response = await axios.get('/api/bookings/today');
                setTodayBookings(response.data);
            } catch (error) {
                console.error("Error fetching today's bookings:", error);
            }
        };

        const fetchLaundryItems = async () => {
            try {
                const response = await axios.get('/api/laundry/laundry-items');
                const filteredItems = response.data.filter(item => !["Bedsheet", "Duvet Cover", "Pillowcase", "Bath Towel", "Hand Towel", "Face Towel", "Bathmat"].includes(item.name));
                setExtraItemOptions(filteredItems.map(item => ({ value: item.name, label: item.name })));
            } catch (error) {
                console.error("Error fetching laundry items:", error);
            }
        };

        fetchTodayBookings();
        fetchLaundryItems();
    }, []);

    const handleQuantityChange = (e, index, list, setList) => {
        const updatedItems = [...list];
        updatedItems[index].quantity = e.target.value ? parseInt(e.target.value, 10) : '';
        setList(updatedItems);
    };

    const handleAddExtraItem = (selectedItem, setItems, items) => {
        if (selectedItem && !items.some(item => item.name === selectedItem.value)) {
            setItems([{ name: selectedItem.value, quantity: '' }, ...items]);
        }
    };

    const handleRemoveExtraItem = (index, setItems, items) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const filteredDropOffExtraItems = dropOffExtraItems.filter(item => item.quantity > 0);
        const filteredPickupExtraItems = pickupExtraItems.filter(item => item.quantity > 0);
    
        const laundryCountData = {
            booking_id: selectedBooking,
            drop_off_items: dropOffItems.concat(filteredDropOffExtraItems).map(item => ({
                name: item.name,
                quantity: item.quantity !== '' ? item.quantity : 0
            })),
            pickup_items: pickupItems.concat(filteredPickupExtraItems).map(item => ({
                name: item.name,
                quantity: item.quantity !== '' ? item.quantity : 0
            })),
            comment: comment
        };
    
        try {
            const response = await axios.post('/api/laundry', laundryCountData);
            if (response.status === 201) {
                alert('Laundry count submitted successfully');
                navigate(`/laundry/${response.data.booking_id}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error === 'Laundry count already exists for this booking.') {
                alert('There is already a laundry count for this booking');
            } else {
                console.error('Error submitting laundry count:', error);
                alert('Failed to submit laundry count');
            }
        }
    };    

    return (
        <div className="add-laundry-container">
            <h2>Add Laundry Count</h2>
            <form onSubmit={handleSubmit}>
                {/* Booking Selection */}
                <div className="form-group">
                    <label>Select Booking (Today)</label>
                    <Select
                        options={todayBookings.map(booking => ({
                            value: booking.booking_id,
                            label: `Booking ID: #${String(booking.booking_id).padStart(6, '0')} - ${booking.client_name} - Unit ${booking.unit_number} - ${booking.building_name} (${booking.bedrooms} - ${booking.bathrooms})`
                        }))}
                        onChange={(selectedOption) => {
                            setSelectedBooking(selectedOption.value);
                        }}
                        placeholder="Select a Booking"
                        isSearchable
                        required
                        className="select-booking"
                    />
                </div>

                {/* Drop-off and Pickup Lists */}
                <div className="laundry-lists-container">
                    <div className="list-section">
                        <h3>Drop Off Items</h3>
                        {dropOffItems.map((item, index) => (
                            <div key={index} className="item-row">
                                <span className="item-name">{item.name}</span>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleQuantityChange(e, index, dropOffItems, setDropOffItems)}
                                    placeholder="Quantity"
                                    className="quantity-input"
                                    onWheel={(e) => e.target.blur()} // Prevent changing value on scroll
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault(); // Prevent changing value with arrow keys
                                        }
                                    }}
                                />
                            </div>
                        ))}

                        <h4>Add Extra Drop Off Items</h4>
                        {dropOffExtraItems.length > 0 && (
                            <div className="extra-items-list">
                                {dropOffExtraItems.map((item, index) => (
                                    <div key={index} className="item-row">
                                        <span className="item-name">{item.name}</span>
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(e, index, dropOffExtraItems, setDropOffExtraItems)}
                                            placeholder="Quantity"
                                            className="quantity-input"
                                            onWheel={(e) => e.target.blur()} // Prevent changing value on scroll
                                            onKeyDown={(e) => {
                                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                    e.preventDefault(); // Prevent changing value with arrow keys
                                                }
                                            }}
                                        />
                                        <span
                                            className="remove-extra-item-button remove-icon"
                                            onClick={() => handleRemoveExtraItem(index, setDropOffExtraItems, dropOffExtraItems)}
                                            style={{ cursor: 'pointer', color: 'grey', fontSize: '1.5rem' }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="extra-item-selection">
                            <Select
                                options={extraItemOptions}
                                onChange={(selectedOption) => setSelectedExtraDropOffItem(selectedOption)}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button
                                type="button"
                                className="add-extra-item-button"
                                onClick={() => handleAddExtraItem(selectedExtraDropOffItem, setDropOffExtraItems, dropOffExtraItems)}
                            >
                                + Extra Item
                            </button>
                        </div>
                    </div>

                    <div className="list-section">
                        <h3>Pickup Items</h3>
                        {pickupItems.map((item, index) => (
                            <div key={index} className="item-row">
                                <span className="item-name">{item.name}</span>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleQuantityChange(e, index, pickupItems, setPickupItems)}
                                    placeholder="Quantity"
                                    className="quantity-input"
                                    onWheel={(e) => e.target.blur()} // Prevent changing value on scroll
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault(); // Prevent changing value with arrow keys
                                        }
                                    }}
                                />
                            </div>
                        ))}

                        <h4>Add Extra Pickup Items</h4>
                        {pickupExtraItems.length > 0 && (
                            <div className="extra-items-list">
                                {pickupExtraItems.map((item, index) => (
                                    <div key={index} className="item-row">
                                        <span className="item-name">{item.name}</span>
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(e, index, pickupExtraItems, setPickupExtraItems)}
                                            placeholder="Quantity"
                                            className="quantity-input"
                                            onWheel={(e) => e.target.blur()} // Prevent changing value on scroll
                                            onKeyDown={(e) => {
                                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                    e.preventDefault(); // Prevent changing value with arrow keys
                                                }
                                            }}
                                        />
                                        <span
                                            className="remove-extra-item-button remove-icon"
                                            onClick={() => handleRemoveExtraItem(index, setPickupExtraItems, pickupExtraItems)}
                                            style={{ cursor: 'pointer', color: 'grey', fontSize: '1.5rem' }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="extra-item-selection">
                            <Select
                                options={extraItemOptions}
                                onChange={(selectedOption) => setSelectedExtraPickupItem(selectedOption)}
                                placeholder="Select Extra Item"
                                className="select-extra-item"
                            />
                            <button
                                type="button"
                                className="add-extra-item-button"
                                onClick={() => handleAddExtraItem(selectedExtraPickupItem, setPickupExtraItems, pickupExtraItems)}
                            >
                                + Extra Item
                            </button>
                        </div>
                    </div>
                </div>

                {/* Comment Section */}
                <div className="form-group">
                    <label>Comment</label>
                    <textarea
                        rows="3"
                        placeholder="Add any comments related to this laundry count"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="comment-field"
                    />
                </div>

                {/* Submit Button */}
                <button type="submit" className="submit-button">Submit Laundry Count</button>
            </form>
        </div>
    );
}

export default AddLaundry;
