import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuttleVan, faHome, faUsers, faBuilding, faCalendarAlt, faUserFriends, faCog, faSoap, faWarehouse, faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './App.css';

// CLIENTS
import Clients from './components/Clients/Clients';
import ClientDetails from './components/Clients/ClientDetails';
import AddClient from './components/Clients/AddClient';
import EditClient from './components/Clients/EditClient';

// PROPERTIES
import Properties from './components/Properties/Properties';
import PropertyDetails from './components/Properties/PropertyDetails';
import AddProperty from './components/Properties/AddProperty';
import EditProperty from './components/Properties/EditProperty';

// BOOKINGS
import Bookings from './components/Bookings/Bookings';
import BookingDetails from './components/Bookings/BookingDetails';
import AddBooking from './components/Bookings/AddBooking';
import EditBooking from './components/Bookings/EditBooking';
import DailyBookings from './components/Bookings/DailyBookings';
import SummaryReport from './components/Bookings/SummaryReport';


// TEAM
import Team from './components/Team/Team';
import AddTeamMember from './components/Team/AddTeamMember';
import EditTeamMember from './components/Team/EditTeamMember';

// LAUNDRY
import Laundry from './components/Laundry/Laundry';
import AddLaundry from './components/Laundry/AddLaundry';
import ConfirmLaundry from './components/Laundry/ConfirmLaundry';
import LaundryDetails from './components/Laundry/LaundryDetails';
import EditLaundry from './components/Laundry/EditLaundry';
import PackingLaundry from './components/Laundry/PackingLaundry';

// WAREHOUSE
import Warehouse from './components/Warehouse/Warehouse';

// Drivers
import Drivers from './components/Drivers/Drivers';

// Settings
import Settings from './components/Settings/Settings';
import Login from './components/Settings/Login';
import ResetPassword from './components/Settings/ResetPassword';
import ForgotPassword from './components/Settings/ForgotPassword';

import ProtectedRoute from './components/ProtectedRoute';


// HOME
import Home from './components/Home';

// Configure Axios to attach token to every request
axios.interceptors.request.use(
  (config) => {
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('token='))
      ?.split('=')[1];

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <div className="dashboard-container">
        <button className="mobile-menu-button" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        <main className="main-content">
          <Routes>
            {/* Public Route for Login */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* Protected Routes */}
            <Route path="/" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse', 'driver']}><Home /></ProtectedRoute>} />

            {/* Warehouse */}
            <Route path="/warehouse" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse']}><Warehouse /></ProtectedRoute>} />

            {/* Clients */}
            <Route path="/clients" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Clients /></ProtectedRoute>} />
            <Route path="/clients/:clientId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><ClientDetails /></ProtectedRoute>} />
            <Route path="/clients/new" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddClient /></ProtectedRoute>} />
            <Route path="/clients/edit/:clientId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EditClient /></ProtectedRoute>} />

            {/* Properties */}
            <Route path="/properties" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Properties /></ProtectedRoute>} />
            <Route path="/properties/new" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddProperty /></ProtectedRoute>} />
            <Route path="/properties/:propertyId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><PropertyDetails /></ProtectedRoute>} />
            <Route path="/properties/edit/:propertyId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EditProperty /></ProtectedRoute>} />

            {/* Bookings */}
            <Route path="/bookings" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Bookings /></ProtectedRoute>} />
            <Route path="/bookings/:bookingId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><BookingDetails /></ProtectedRoute>} />
            <Route path="/bookings/new" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddBooking /></ProtectedRoute>} />
            <Route path="/bookings/edit/:bookingId" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EditBooking /></ProtectedRoute>} />
            <Route path="/bookings/report/:date" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><DailyBookings /></ProtectedRoute>} />
            <Route path="/bookings/summary-report" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><SummaryReport /></ProtectedRoute>} />

            {/* Team */}
            <Route path="/team" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Team /></ProtectedRoute>} />
            <Route path="/team/new" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddTeamMember /></ProtectedRoute>} />
            <Route path="/team/edit/:id" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EditTeamMember /></ProtectedRoute>} />

            {/* Laundry */}
            <Route path="/laundry" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse']}><Laundry /></ProtectedRoute>} />
            <Route path="/laundry/new" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse']}><AddLaundry /></ProtectedRoute>} />
            <Route path="/laundry/confirm/:id" element={<ProtectedRoute allowedRoles={['manager', 'warehouse']}><ConfirmLaundry /></ProtectedRoute>} />
            <Route path="/laundry/packing/:id" element={<ProtectedRoute allowedRoles={['manager', 'warehouse']}><PackingLaundry /></ProtectedRoute>} />
            <Route path="/laundry/:id" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse']}><LaundryDetails /></ProtectedRoute>} />
            <Route path="/laundry/edit/:id" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EditLaundry /></ProtectedRoute>} />

            {/* Drivers */}
            <Route path="/drivers" element={<ProtectedRoute allowedRoles={['driver', 'manager', 'admin']}><Drivers /></ProtectedRoute>} />

            {/* Settings */}
            <Route path="/settings" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'warehouse', 'driver']}><Settings /></ProtectedRoute>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

// Sidebar with navigation links
function Sidebar({ isOpen, toggleSidebar }) {
  const userRole = localStorage.getItem('userRole');

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="logo">
        <Link to="/" onClick={toggleSidebar}>
          <img src="/logo.png" alt="Logo" />
        </Link>
      </div>
      <nav>
        <ul>
          {(userRole === 'admin' || userRole === 'manager') && (
            <>
              <li className="nav-item">
                <Link to="/" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/clients" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faUsers} />
                  </div>
                  Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/properties" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  Properties
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/bookings" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                  Bookings
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/team" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faUserFriends} />
                  </div>
                  Team
                </Link>
              </li>
            </>
          )}
          {(userRole === 'admin' || userRole === 'manager' || userRole === 'warehouse') && (
            <>
              <li className="nav-item">
                <Link to="/warehouse" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faWarehouse} />
                  </div>
                  Warehouse
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/laundry" onClick={toggleSidebar}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faSoap} />
                  </div>
                  Laundry
                </Link>
              </li>
            </>
          )}
          {(userRole === 'admin' || userRole === 'manager' || userRole === 'driver') && (
            <li className="nav-item">
              <Link to="/drivers" onClick={toggleSidebar}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faShuttleVan} />
                </div>
                Drivers
              </Link>
            </li>
          )}
          {(userRole === 'admin' || userRole === 'manager' || userRole === 'warehouse' || userRole === 'driver') && (
            <li className="nav-item">
              <Link to="/settings" onClick={toggleSidebar}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faCog} />
                </div>
                Settings
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default App;