import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './addClient.css';
import axios from 'axios';

function AddClient() {
  const [newClient, setNewClient] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    display_name: '',
    email: '',
    all_properties_notes: '',
    contact_number: '',
    trn_number: '',
    address_line_1: '',
    floor_number: '',
    unit_number: '',
    city: '',
    state: '',
    country: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (newClient.company_name) {
      setNewClient((prevClient) => ({
        ...prevClient,
        display_name: newClient.company_name.length >= 4 ? newClient.company_name : '',
      }));
    } else {
      const generatedName = `${newClient.first_name} ${newClient.last_name}`.trim();
      setNewClient((prevClient) => ({
        ...prevClient,
        display_name: generatedName.length >= 4 ? generatedName : '',
      }));
    }
  }, [newClient.first_name, newClient.last_name, newClient.company_name]);

  const handleAddClient = async (e) => {
    e.preventDefault();

    // Check if display_name is at least 4 characters
    if (newClient.display_name.trim().length < 4) {
      alert('Display Name must be at least 4 characters long');
      return;
    }

    try {
      const response = await axios.post('/api/clients', newClient);
      if (response.status === 201) { // Assuming 201 Created status on success
        navigate('/clients');
      } else {
        console.error('Error adding client:', response);
      }
    } catch (error) {
      console.error('Error adding client:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="add-client-container">
      <h2>Add New Client</h2>
      <form className="new-client-form" onSubmit={handleAddClient}>
        <h3>Personal Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              value={newClient.first_name}
              onChange={(e) => setNewClient({ ...newClient, first_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              value={newClient.last_name}
              onChange={(e) => setNewClient({ ...newClient, last_name: e.target.value })}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              value={newClient.company_name}
              onChange={(e) => setNewClient({ ...newClient, company_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Display Name</label>
            <input
              type="text"
              value={newClient.display_name}
              onChange={(e) => setNewClient({ ...newClient, display_name: e.target.value })}
              required
              minLength={4}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={newClient.email}
              onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              value={newClient.contact_number}
              onChange={(e) => setNewClient({ ...newClient, contact_number: e.target.value })}
            />
          </div>
        </div>
        <h3>Notes for all properties</h3>
        <div className="form-row">
          <div className="form-group full-width">
            <label>Property Notes</label>
            <textarea
              value={newClient.all_properties_notes}
              onChange={(e) => setNewClient({ ...newClient, all_properties_notes: e.target.value })}
              placeholder="Add notes related to all properties"
              rows="5"
            />
          </div>
        </div>
        <h3>Billing Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>TRN Number</label>
            <input
              type="text"
              value={newClient.trn_number}
              onChange={(e) => setNewClient({ ...newClient, trn_number: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Address Line 1</label>
            <input
              type="text"
              value={newClient.address_line_1}
              onChange={(e) => setNewClient({ ...newClient, address_line_1: e.target.value })}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Floor</label>
            <input
              type="text"
              value={newClient.floor_number}
              onChange={(e) => setNewClient({ ...newClient, floor_number: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Apartment / Office / Unit Number</label>
            <input
              type="text"
              value={newClient.unit_number}
              onChange={(e) => setNewClient({ ...newClient, unit_number: e.target.value })}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              value={newClient.city}
              onChange={(e) => setNewClient({ ...newClient, city: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Emirate</label>
            <input
              type="text"
              value={newClient.state}
              onChange={(e) => setNewClient({ ...newClient, state: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Country</label>
            <input
              type="text"
              value={newClient.country}
              onChange={(e) => setNewClient({ ...newClient, country: e.target.value })}
            />
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default AddClient;
