import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import './addTeamMember.css';

function AddTeamMember() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        password: '', 
        eid: '',
        created_at: '',
    });
    const [roleOptions, setRoleOptions] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Retrieve user role from localStorage
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await axios.get('/api/team/roles');
                const roles = response.data;

                const filteredRoles = roles
                    .filter(role => {
                        // Exclude "client" role and restrict "manager" role to only managers
                        if (role.role_name.toLowerCase() === 'client') return false;
                        if (role.role_name.toLowerCase() === 'manager' && userRole !== 'manager') return false;
                        return true;
                    })
                    .map(role => ({
                        value: role.id,
                        label: role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1),
                    }));

                setRoleOptions(filteredRoles);
            } catch (error) {
                console.error('Error fetching roles:', error);
                setError('Error fetching roles');
            }
        };

        fetchRoles();
    }, [userRole]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRoleChange = (selectedOption) => {
        setFormData({ ...formData, role: selectedOption.value });
    };

    const handleAddMember = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('/api/team/', formData);

            if (response.status === 201) {
                navigate('/team');
            } else if (response.status === 409) {
                setError('Email already exists. Please use a different email.');
            } else {
                setError('Error adding team member. Please try again.');
            }
        } catch (error) {
            console.error('Error adding team member:', error);
            setError('Something went wrong. Please try again.');
        }

        setLoading(false);
    };

    return (
        <div className="add-team-member-container">
            <h2>Add Team Member</h2>
            <form className="add-team-member-form" onSubmit={handleAddMember}>
                {error && <p className="error">{error}</p>}

                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Role</label>
                    <Select
                        options={roleOptions}
                        onChange={handleRoleChange}
                        placeholder="Select Role"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Emirates ID (Optional)</label> 
                    <input
                        type="text"
                        name="eid"
                        value={formData.eid}
                        onChange={handleInputChange}
                        placeholder="Enter Emirates ID"
                    />
                </div>

                <div className="form-group">
                    <label>Date of Join</label> 
                    <input
                        type="date"
                        name="created_at"
                        value={formData.created_at}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Password (Optional)</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder="Leave blank for no password"
                    />
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Adding...' : 'Add Team Member'}
                </button>
            </form>
        </div>
    );
}

export default AddTeamMember;