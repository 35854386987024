import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './settings.css'; // Import the new CSS file for styling

function Settings() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  // Handle password change
  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Check if the new password and confirm new password match
    if (newPassword !== confirmNewPassword) {
      setError('New password and confirm new password do not match');
      return;
    }

    try {
      await axios.post('/api/users/change_password', {
        currentPassword,
        newPassword
      });
      setSuccess('Password changed successfully');
      setError('');
      setCurrentPassword(''); // Clear input fields
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setError('Failed to change password. Please try again.');
      setSuccess('');
    }
  };

  const handleLogout = () => {
    // If using localStorage:
    localStorage.removeItem('token');

    // If using cookies:
    document.cookie = 'token=; Max-Age=0; path=/'; // Clears the cookie

    navigate('/login');
  };


  return (
    <div className="settings-container">
      <h1 className="settings-title">Settings</h1>
      <form onSubmit={handleChangePassword} className="settings-form">
        <div className="form-group">
          <label>Current Password</label>
          <input
            type="password"
            placeholder="Enter current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="settings-input"
          />
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="settings-input"
          />
        </div>
        <div className="form-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm new password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
            className="settings-input"
          />
        </div>
        <button type="submit" className="settings-button">Change Password</button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>

      <hr />

      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
}

export default Settings;
