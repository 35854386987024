import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './packingLaundry.css';

function PackingLaundry() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [pickupItems, setPickupItems] = useState([]);
    const [existingReports, setExistingReports] = useState([]);
    const [newReport, setNewReport] = useState({ type: '', item: null, description: '', photo: null });
    const [packedPhoto, setPackedPhoto] = useState(null);
    const [blueBags, setBlueBags] = useState(null);
    const [propertyInfo, setPropertyInfo] = useState({});
    const [loading, setLoading] = useState(false); // NEW: Loading state
    const [feedbackMessage, setFeedbackMessage] = useState(''); // NEW: Feedback message



    const fetchExistingReports = useCallback(async () => {
        try {
            const reportsResponse = await axios.get(`/api/attachments/laundry/reported-images/${id}`);
            setExistingReports(reportsResponse.data);
        } catch (error) {
            console.error("Error fetching existing reports:", error);
        }
    }, [id]);

    const getAreaColor = (area) => {
        switch (area) {
            case 'Downtown Dubai':
            case 'Business Bay':
                return '#4c7ac5';
            case 'Marina':
            case 'JLT':
            case 'JBR':
                return '#ae2021';
            case 'Palm Jumeirah':
                return '#d9b342';
            case 'Creek Harbour':
                return '#476f37';
            case 'Azizi Riviera':
                return '#a43777';
            default:
                return '#6c757d';
        }
    };

    useEffect(() => {
        const fetchLaundryData = async () => {
            try {
                const pickupResponse = await axios.get(`/api/laundry/${id}/pickup-items`);
                setPickupItems(pickupResponse.data.map(item => ({
                    value: item.laundry_item_id,
                    label: item.name,
                    quantity: item.quantity_pickup
                })));
            } catch (error) {
                console.error("Error fetching laundry data:", error);
            }
        };

        const fetchPropertyInfo = async () => {
            try {
                const propertyResponse = await axios.get(`/api/laundry/${id}/details`);
                const data = propertyResponse.data;
                setPropertyInfo({
                    propertyId: String(data.property_id).padStart(6, '0'),
                    clientDisplayName: data.client_name,
                    buildingName: data.building_name,
                    unitNumber: data.unit_number,
                    area: data.area // Assuming area is part of the response data
                });
            } catch (error) {
                console.error("Error fetching property information:", error);
            }
        };

        fetchLaundryData();
        fetchExistingReports();
        fetchPropertyInfo();
    }, [id, fetchExistingReports]);

    const closeFeedbackOverlay = () => {
        setFeedbackMessage(''); // Clears the feedback message
    };


    const handleNewReportChange = (field, value) => {
        setNewReport({ ...newReport, [field]: value });
    };

    const handleUploadReport = async () => {
        if (!newReport.type || !newReport.item || !newReport.photo) {
            setFeedbackMessage("Please complete all fields before uploading.");
            return;
        }

        const formData = new FormData();
        formData.append('photos', newReport.photo);
        formData.append('laundry_item_id', newReport.item.value);
        formData.append('description', newReport.description);

        try {
            setLoading(true); // Show loading screen
            await axios.post(`/api/attachments/laundry/${id}/${newReport.type}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            await fetchExistingReports();
            setNewReport({ type: '', item: null, description: '', photo: null });
            setFeedbackMessage("Report uploaded successfully.");
        } catch (error) {
            console.error("Error uploading report:", error);
            setFeedbackMessage("Failed to upload report. Please try again.");
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const removeReportImage = async (index) => {
        const reportToRemove = existingReports[index];
        const photoId = reportToRemove.id;

        const confirmDelete = window.confirm("Are you sure you want to remove this item?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/attachments/laundry/${id}/${photoId}`);
            setExistingReports(existingReports.filter((_, i) => i !== index));
        } catch (error) {
            console.error("Error removing report image:", error);
            alert("Failed to remove report image");
        }
    };

    const handlePackedPhotoChange = (e) => {
        setPackedPhoto(e.target.files[0]);
    };

    const handlePackedPhotoSubmit = async (e) => {
        e.preventDefault();

        if (!packedPhoto || !blueBags) {
            setFeedbackMessage("Please upload a photo and select the number of bags.");
            return;
        }

        const formData = new FormData();
        formData.append('photos', packedPhoto);
        formData.append('bags', blueBags);
        formData.append('packed_at', new Date().toISOString());

        try {
            setLoading(true); // Show loading screen
            await axios.post(`/api/attachments/laundry/packing/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setFeedbackMessage("Packed photo submitted successfully.");
            setTimeout(() => navigate('/laundry'), 2000); // Navigate after success
        } catch (error) {
            console.error("Error uploading packed photo:", error);
            setFeedbackMessage("Failed to upload packed photo.");
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const getReportedItemsSummary = () => {
        const summary = {};
        existingReports.forEach(report => {
            if (summary[report.item_name]) {
                summary[report.item_name] += 1;
            } else {
                summary[report.item_name] = 1;
            }
        });
        return summary;
    };

    const propertyInfoDisplay = `${propertyInfo.clientDisplayName} - ${propertyInfo.buildingName} - Unit ${propertyInfo.unitNumber}`;
    const areaColor = getAreaColor(propertyInfo.area);

    return (

        <div className="packing-process-container">
            {(loading || feedbackMessage) && (
                <div className="loading-overlay">
                    <div className="loading-box">
                        {loading ? (
                            <>
                                <div className="spinner"></div>
                                <p>Loading...</p>
                            </>
                        ) : (
                            <>
                                <p>{feedbackMessage}</p>
                                <button className="ok-button" onClick={closeFeedbackOverlay}>
                                    OK
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {/* Property Information */}
            <div className="property-info-packing" style={{ color: areaColor }}>
                <h2>Property ID: #{propertyInfo.propertyId}</h2>
                <p>{propertyInfoDisplay}</p>
            </div>

            {step === 1 && (
                <>
                    {existingReports.length > 0 && (
                        <h2>Existing Reports</h2>
                    )}
                    <div className="existing-reports">
                        {existingReports.map((report, index) => (
                            <div key={index} className="report-item existing-report">
                                <span>{index + 1}.</span>
                                <img
                                    src={report.image_url}
                                    alt={`${report.type}`}
                                    className="report-photo-preview"
                                    onClick={() => window.open(report.image_url, '_blank')}
                                />
                                <div className="existing-report-content">
                                    <p><span>Item:</span> {report.item_name}</p>
                                    <p><span>Type:</span> {report.type}</p>
                                    <p><span>Description:</span> {report.description}</p>
                                </div>
                                <div className="existing-report-actions">
                                    <button
                                        type="button"
                                        onClick={() => removeReportImage(index)}
                                        className="remove-button">
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h2>Report New Stains or Damages</h2>
                    <div className="report-item">
                        <Select
                            options={pickupItems}
                            value={newReport.item}
                            onChange={(selected) => handleNewReportChange('item', selected)}
                            placeholder="Select Item"
                            className="select-field"
                        />
                        <Select
                            options={[
                                { value: 'stain', label: 'Stain' },
                                { value: 'damage', label: 'Damage' }
                            ]}
                            value={{ value: newReport.type, label: newReport.type.charAt(0).toUpperCase() + newReport.type.slice(1) }}
                            onChange={(selected) => handleNewReportChange('type', selected.value)}
                            placeholder="Select Type"
                            className="select-field"
                        />
                        <input
                            type="text"
                            placeholder="Description"
                            value={newReport.description}
                            onChange={(e) => handleNewReportChange('description', e.target.value)}
                            className="text-field"
                        />
                        <input
                            type="file"
                            onChange={(e) => handleNewReportChange('photo', e.target.files[0])}
                            className="file-field"
                        />
                        <button type="button" onClick={handleUploadReport} className="submit-report">
                            Upload Report
                        </button>
                    </div>

                    <div className="button-group">
                        <button
                            type="button"
                            onClick={() => setStep(2)}
                            className="no-stains-report"
                        >
                            {existingReports.length > 0 ? "All Stains Reported" : "No Stains to Report"}
                        </button>
                    </div>
                </>
            )}

            {step === 2 && (
                <>
                    <h2>Confirm Packed Items</h2>
                    <div className="step-2-columns">
                        <div className="packed-items-column">
                            <h3>Packed Items</h3>
                            <ul className="packed-items-list">
                                {pickupItems.map((item, index) => (
                                    <li key={index}>{item.label}: {item.quantity} pcs</li>
                                ))}
                            </ul>
                        </div>

                        {existingReports.length > 0 && (
                            <div className="stained-items-column">
                                <h3>Stained/Damaged Items</h3>
                                <ul className="stained-items-list">
                                    {Object.entries(getReportedItemsSummary()).map(([itemName, count], index) => (
                                        <li key={index}>{itemName}: {count} pcs stained/damaged</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="blue-bags-section">
                        <label htmlFor="blueBags">How many blue bags did you use for packing?</label>
                        <Select
                            options={[1, 2, 3, 4].map(num => ({ value: num, label: `${num} bag${num > 1 ? 's' : ''}` }))}
                            value={blueBags ? { value: blueBags, label: `${blueBags} bag${blueBags > 1 ? 's' : ''}` } : null}
                            onChange={(selected) => setBlueBags(selected.value)}
                            placeholder="Select number of bags"
                            className="select-field"
                        />
                    </div>

                    <form onSubmit={handlePackedPhotoSubmit} className="packed-photo-form">
                        <p>Please confirm that you have packed all picked items by uploading a photo.</p>
                        <input type="file" onChange={handlePackedPhotoChange} className="file-field" />
                        <button type="submit" className="submit-packed-photo">Submit Packed Photo</button>
                    </form>
                </>
            )}
        </div>
    );
}

export default PackingLaundry;