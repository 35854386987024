import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './laundryDetails.css';
import ImageManager from './ImageManager';

function LaundryDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laundryDetails, setLaundryDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [beforePhotos, setBeforePhotos] = useState([]);
    const [damagePhotos, setDamagePhotos] = useState([]);
    const [stainPhotos, setStainPhotos] = useState([]);
    const [packingPhotos, setPackingPhotos] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadType, setUploadType] = useState('before');
    const [selectedStainItem, setSelectedStainItem] = useState('');
    const [userRole] = useState(localStorage.getItem('userRole'));
    const [latestLaundry, setLatestLaundry] = useState(null);
    const [customStandardQuantities, setCustomStandardQuantities] = useState([]);
    const [useCustomSQ, setUseCustomSQ] = useState(false);

    const itemPriority = [
        'Bedsheet',
        'Duvet Cover',
        'Pillowcase',
        'Bath Towel',
        'Hand Towel',
        'Face Towel',
        'Bathmat',
        'Beach Towel',
    ];

    const sortItemsByPriority = (items) => {
        return items.sort((a, b) => {
            const aPriority = itemPriority.indexOf(a.name || a.laundry_item_name);
            const bPriority = itemPriority.indexOf(b.name || b.laundry_item_name);

            // If both items are in the priority list, compare by index
            if (aPriority !== -1 && bPriority !== -1) {
                return aPriority - bPriority;
            }

            // If only one item is in the priority list, it should come first
            if (aPriority !== -1) return -1;
            if (bPriority !== -1) return 1;

            // If neither item is in the priority list, sort alphabetically
            return (a.name || a.laundry_item_name).localeCompare(b.name || b.laundry_item_name);
        });
    };


    // Fetch laundry details
    useEffect(() => {
        const fetchLaundryDetails = async () => {
            try {
                const response = await axios.get(`/api/laundry/${id}/details`);
                setLaundryDetails(response.data);

                // Fetch custom SQ if `is_custom_sq` is true
                if (response.data.is_custom_sq && response.data.property_id) {
                    setUseCustomSQ(true);
                    fetchCustomStandardQuantities(response.data.property_id);
                }
            } catch (error) {
                console.error("Error fetching laundry details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLaundryDetails();
    }, [id]);

    // Fetch latest completed laundry details using property_id
    useEffect(() => {
        const fetchLatestLaundry = async () => {
            try {
                if (id) { // Ensure property_id is available
                    const response = await axios.get(`/api/laundry/last-count-before/${id}`);
                    setLatestLaundry(response.data);
                }
            } catch (error) {
                console.error("Error fetching latest completed laundry:", error);
            }
        };

        fetchLatestLaundry();
    }, [id]);

    // Fetch custom SQ if applicable
    const fetchCustomStandardQuantities = async (propertyId) => {
        try {
            const response = await axios.get(`/api/properties/property-sq/${propertyId}`);
            const customSQ = response.data.reduce((acc, item) => {
                acc[item.laundry_item_name] = item.count;
                return acc;
            }, {});
            setCustomStandardQuantities(customSQ);
        } catch (error) {
            console.error("Error fetching custom standard quantities:", error);
        }
    };

    const calculateStandardQuantity = (bedrooms, bathrooms) => {
        const adjustedBedrooms = bedrooms === 0 ? 1 : bedrooms; // Treat 0 bedrooms as 1
        const standardQuantities = {
            'Bedsheet': adjustedBedrooms * 1,
            'Duvet Cover': adjustedBedrooms * 1,
            'Pillowcase': adjustedBedrooms * 4,
            'Bath Towel': adjustedBedrooms * 2,
            'Hand Towel': adjustedBedrooms * 2,
            'Face Towel': adjustedBedrooms * 2,
            'Bathmat': Math.ceil(bathrooms * 1),
        };

        return standardQuantities;
    };

    const getStandardQuantities = () => {
        if (useCustomSQ) {
            return customStandardQuantities;
        }
        return calculateStandardQuantity(laundryDetails.bedrooms, laundryDetails.bathrooms);
    };

    const fetchPhotosByType = useCallback(async (type, setPhotos) => {
        try {
            const response = await axios.get(`/api/attachments/laundry/${id}/${type}`);
            if (response.status === 200 && response.data.length > 0) {
                setPhotos(response.data);
            } else {
                console.warn(`No ${type} photos found.`);
                setPhotos([]);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.warn(`No ${type} photos found.`);
                setPhotos([]);
            } else {
                console.error(`Error fetching ${type} photos:`, error);
            }
        }
    }, [id]);

    useEffect(() => {
        fetchPhotosByType('before', setBeforePhotos);
        fetchPhotosByType('damage', setDamagePhotos);
        fetchPhotosByType('stain', setStainPhotos);
        fetchPhotosByType('packing', setPackingPhotos);
    }, [fetchPhotosByType]);

    const handleEditClick = () => {
        navigate(`/laundry/edit/${id}`);
    };

    const handleDeletePhoto = async (photoId, type) => {
        const confirmed = window.confirm(`Are you sure you want to delete this ${type} photo?`);
        if (!confirmed) return;

        try {
            // Delete photo on the server
            await axios.delete(`/api/attachments/laundry/${id}/${photoId}`);

            // Directly update local state for immediate feedback without reloading
            if (type === 'stain') {
                setStainPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
            } else if (type === 'damage') {
                setDamagePhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
            } else {
                const setPhotos = getSetterByType(type);
                setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
            }

        } catch (error) {
            console.error(`Error deleting ${type} photo:`, error);
        }
    };


    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        const newFiles = files.map((file) => ({ file, preview: URL.createObjectURL(file) }));
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleFileUpload = async () => {
        if (selectedFiles.length === 0) return;

        const formData = new FormData();
        selectedFiles.forEach(({ file }) => {
            formData.append('photos', file);
        });

        let laundry_item_id = null;
        let bags = null;

        if (uploadType === 'stain' || uploadType === 'damage') {
            const selectedItem = laundryDetails.laundry_items.find(item => item.name === selectedStainItem);
            if (selectedItem) {
                laundry_item_id = selectedItem.laundry_item_id;
                formData.append('laundry_item_id', laundry_item_id);
            }
        }

        if (uploadType === 'packing') {
            bags = laundryDetails.bags || 1;
            formData.append('bags', bags);
        }

        try {
            setIsUploading(true);
            await axios.post(`/api/attachments/laundry/${id}/${uploadType}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setSelectedFiles([]);
            // Fetch the photos again after upload to refresh the display
            fetchPhotosByType(uploadType, getSetterByType(uploadType));
        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const getSetterByType = (type) => {
        switch (type) {
            case 'before':
                return setBeforePhotos;
            case 'damage':
                return setDamagePhotos;
            case 'stain':
                return setStainPhotos;
            case 'packing':
                return setPackingPhotos;
            default:
                return () => { };
        }
    };

    const formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('en-GB') : 'N/A';
    };

    const formatTime = (time) => {
        return time ? new Date(time).toTimeString().slice(0, 5) : 'N/A';
    };

    if (loading) {
        return <p>Loading laundry details...</p>;
    }

    const {
        booking_id,
        property_id,
        client_name,
        building_name,
        unit_number,
        bedrooms,
        bathrooms,
        laundry_items = [],
        created_at,
        status,
        comment,  // Accessing `comment` from laundry_details
        created_by_first_name,
        packed_by_first_name,
        confirmed_by_first_name,
        confirmed_at,
        packed_at
    } = laundryDetails;

    const bedroomDisplay = bedrooms === 0 ? 'S' : bedrooms;
    const addressDisplay = `${client_name || ''} - ${building_name || ''} - Unit ${unit_number || ''} (${bedroomDisplay} - ${bathrooms || 'N/A'})`;

    const standardQuantities = getStandardQuantities();

    const hasMismatch = laundry_items.some(
        (item) => item.quantity_warehouse_confirmation !== item.quantity_pickup
    );

    const statusConfig = {
        created: 'Waiting for warehouse confirmation',
        processing: 'Processing',
        flagged: 'Incorrect Warehouse Confirmation',
        completed: 'Packed & Ready',
        before_photo: 'Awaiting Before Photos',
        stains_reported: 'Stains Reported',
        delivered: 'Delivered',
    };

    const statusLabel = statusConfig[status] || 'Unknown';

    const getAllRelevantItems = () => {
        const allItemsSet = new Set();

        // Add items from latestLaundry, currentItems, and standardQuantities
        if (latestLaundry?.laundry_items) {
            latestLaundry.laundry_items.forEach((item) => allItemsSet.add(item.name));
        }
        if (laundry_items) {
            laundry_items.forEach((item) => allItemsSet.add(item.name));
        }
        Object.keys(standardQuantities).forEach((itemName) => allItemsSet.add(itemName));

        // Convert the set to an array and map items with full details
        const allItemsArray = Array.from(allItemsSet).map((itemName) => {
            const standardQuantity = standardQuantities[itemName] || 0;
            const latestItem = latestLaundry?.laundry_items?.find((item) => item.name === itemName) || {};
            const currentItem = laundry_items.find((item) => item.name === itemName) || {};

            return {
                name: itemName,
                standardQuantity,
                latestItem,
                currentItem,
            };
        });

        // Sort items by priority or alphabetically
        return sortItemsByPriority(allItemsArray);
    };


    return (
        <div className="laundry-details-container">
            <div className="header">
                <h2>Booking: #{String(booking_id).padStart(6, '0')}</h2>
                {(userRole === 'admin' || userRole === 'manager') && (
                    <button className="edit-button" onClick={handleEditClick}>
                        Edit Laundry Count
                    </button>
                )}
            </div>

            <div className="detail-full-width">
                <p><strong>Unit:</strong> {addressDisplay}</p>
            </div>
            <div className="client-property-details">
                <div className="detail-column">
                    <p><strong>Created At:</strong> {created_at ? new Date(created_at).toLocaleDateString() : 'N/A'}</p>
                    <p><strong>Property ID:</strong> #{property_id?.toString().padStart(6, '0') || 'N/A'}</p>
                    <p><strong>Status:</strong> {statusLabel}</p>
                </div>
                <div className="detail-column">
                    <p><strong>Created By:</strong> {created_by_first_name || 'N/A'}</p>
                    <p><strong>Confirmed By:</strong> {confirmed_by_first_name || 'Processing'} {(userRole === 'manager') && (
                        <span>, {formatDate(confirmed_at)} {formatTime(confirmed_at)}</span>)}
                    </p>
                    <p><strong>Packed By:</strong> {packed_by_first_name || 'Processing'} {(userRole === 'manager') && (
                        <span>, {formatDate(packed_at)} {formatTime(packed_at)}</span>)}
                    </p>

                </div>
            </div>
            <table className="laundry-details-table">
                <thead>
                    {/* Row for showing dates */}
                    <tr>
                        <th></th> {/* Empty header for "Item Name" */}
                        <th colSpan="2">
                            {latestLaundry?.created_at ? (
                                <a href={`/laundry/${latestLaundry.booking_id}`}>
                                    {formatDate(latestLaundry.created_at)}
                                </a>
                            ) : 'N/A'}
                        </th>
                        <th colSpan="2">
                            {laundryDetails.created_at ? (
                                <a href={`/laundry/${laundryDetails.booking_id}`}>
                                    {formatDate(laundryDetails.created_at)}
                                </a>
                            ) : 'N/A'}
                        </th>
                        {hasMismatch && <th></th>} {/* Placeholder for WH column */}
                    </tr>
                    {/* Row for column titles */}
                    <tr>
                        <th style={{ textAlign: 'left' }}>Item Name (SQ)</th>
                        <th>Last Drop</th>
                        <th>Last Pick</th>
                        <th>Drop</th>
                        <th>Pick</th>
                        {hasMismatch && <th>WH</th>}
                    </tr>
                </thead>
                <tbody>
                    {getAllRelevantItems().map(({ name, standardQuantity, latestItem, currentItem }) => {
                        const isLastPickMismatch = latestItem.quantity_pickup !== currentItem.quantity_dropoff;
                        const isLastDropMismatch = latestItem.quantity_dropoff !== currentItem.quantity_pickup;
                        const isWarehouseMismatch =
                            currentItem.quantity_warehouse_confirmation !== currentItem.quantity_pickup;

                        return (
                            <tr key={name}>
                                <td className="first-column" style={{ textAlign: 'left' }}>
                                    {name} ({standardQuantity})
                                </td>
                                <td className={isLastDropMismatch ? 'warning-highlight' : ''}>
                                    {latestItem.quantity_dropoff !== undefined ? latestItem.quantity_dropoff : '-'}
                                </td>
                                <td className={isLastPickMismatch ? 'mismatch-highlight' : ''}>
                                    {latestItem.quantity_pickup !== undefined ? latestItem.quantity_pickup : '-'}
                                </td>
                                <td className={isLastPickMismatch ? 'mismatch-highlight' : ''}>
                                    {currentItem.quantity_dropoff !== undefined ? currentItem.quantity_dropoff : '-'}
                                </td>
                                <td className={isLastDropMismatch ? 'warning-highlight' : ''}>
                                    {currentItem.quantity_pickup !== undefined ? currentItem.quantity_pickup : '-'}
                                </td>
                                {hasMismatch && (
                                    <td className={isWarehouseMismatch ? 'mismatch' : ''}>
                                        {currentItem.quantity_warehouse_confirmation !== null
                                            ? currentItem.quantity_warehouse_confirmation
                                            : 'Not Confirmed'}
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Display the comment */}
            {comment && (
                <div className="laundry-comment">
                    <strong>Comment:</strong> {comment}
                </div>
            )}

            <ImageManager
                userRole={userRole}
                beforePhotos={beforePhotos}
                stainPhotos={stainPhotos}
                damagePhotos={damagePhotos}
                packingPhotos={packingPhotos}
                isEditMode={isEditMode}
                handleFileSelect={handleFileSelect}
                handleFileUpload={handleFileUpload}
                handleDeletePhoto={handleDeletePhoto}
                toggleEditMode={toggleEditMode}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isUploading={isUploading}
                setUploadType={setUploadType}
                uploadType={uploadType}
                selectedStainItem={selectedStainItem}
                setSelectedStainItem={setSelectedStainItem}
                laundryItems={laundry_items}
            />
        </div>
    );
}

export default LaundryDetails;
