import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './laundry.css';

function Laundry() {
  const [laundryItems, setLaundryItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusVisibility, setStatusVisibility] = useState({});
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole'); // Retrieve user role

  // Fetch laundry data from backend
  useEffect(() => {
    const fetchLaundryItems = async () => {
      try {
        const response = await axios.get('/api/laundry');
        const data = Array.isArray(response.data) ? response.data : [];
        setLaundryItems(data);

        const initialVisibility = {};
        data.forEach(item => {
          const statusLabel = getStatusDetails(item.status).label;
          if (!(statusLabel in initialVisibility)) {
            initialVisibility[statusLabel] = true;
          }
        });
        setStatusVisibility(initialVisibility);
      } catch (error) {
        console.error('Error fetching laundry items:', error);
        setLaundryItems([]);
      }
    };

    fetchLaundryItems();
  }, []);

  // Function to get status details
  const getStatusDetails = (status) => {
    const statusInfo = {
      created: { label: 'Waiting for warehouse confirmation', color: '#FFA500' },
      processing: { label: 'Processing', color: '#1E90FF' },
      flagged: { label: 'Incorrect Warehouse Confirmation', color: '#DC143C' },
      completed: { label: 'Packed & Ready', color: '#32CD32' },
      delivered: { label: 'Delivered', color: '#32a887' },
      before_photo: { label: 'Awaiting Before Photos', color: '#FFD700' },
      stains_reported: { label: 'Stains Reported', color: '#8A2BE2' }
    };
    return statusInfo[status] || { label: 'Unknown', color: '#808080' };
  };

  // Toggle visibility of items under each status
  const toggleVisibility = (status) => {
    setStatusVisibility((prev) => ({
      ...prev,
      [status]: !prev[status]
    }));
  };

  // Navigate to specific laundry detail page with restrictions for warehouse staff
  const handleBoxClick = (laundryId, status) => {
    if (userRole !== 'warehouse' || (status !== 'created')) {
      navigate(`/laundry/${laundryId}`);
    } else {
      alert("Access restricted for this laundry count.");
    }
  };

  // Navigate to confirmation page for created items
  const handleConfirm = (id, e, propertyInfo) => {
    e.stopPropagation();
    navigate(`/laundry/confirm/${id}?propertyId=${propertyInfo.propertyId}&propertyDisplay=${encodeURIComponent(propertyInfo.propertyDisplay)}`);
  };

  // Group items by status label
  const groupedByStatus = laundryItems.reduce((acc, item) => {
    const statusLabel = getStatusDetails(item.status).label;
    if (!acc[statusLabel]) acc[statusLabel] = [];
    acc[statusLabel].push(item);
    return acc;
  }, {});

  // Order of statuses for display
  const sortedStatuses = [
    'Awaiting Before Photos',
    'Stains Reported',
    'Incorrect Warehouse Confirmation',
    'Waiting for warehouse confirmation',
    'Processing',
    'Packed & Ready',
    'Delivered'
  ];

  // Format Property ID to 6 digits
  const formatPropertyId = (id) => id.toString().padStart(3, '0');

  // Filter items based on search term
  const filterItems = (item) => {
    const { client_name = '', unit_number = '', building_name = '', property_id } = item;
    const propertyId = formatPropertyId(property_id);

    return (
      client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      unit_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      building_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      propertyId.includes(searchTerm)
    );
  };

  return (
    <div className="laundry-container">
      <h2>Laundry Counts</h2>

      <div className="search-and-add-container">
        <input
          type="text"
          placeholder="Search by Property ID, Client Name, Unit Number, or Building Name..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        {userRole !== 'warehouse' && (
          <Link to="/laundry/new">
            <button className="add-laundry-button">+ Add Laundry Count</button>
          </Link>
        )}
      </div>

      {sortedStatuses.map((status) => (
        <div key={status} className="status-group">
          {groupedByStatus[status] && (
            <>
              <button
                className="status-toggle"
                onClick={() => toggleVisibility(status)}
                style={{ backgroundColor: getStatusDetails(status).color }}
              >
                {status} ({groupedByStatus[status].length})
                <span className="toggle-icon">{statusVisibility[status] ? '▼' : '►'}</span>
              </button>
              {statusVisibility[status] && (
                <div className="laundry-status-group">
                  {groupedByStatus[status]
                    .filter(filterItems)
                    .sort((a, b) => {
                      const isDeliveredOrCompleted = ['delivered', 'completed'].includes(a.status);
                      if (isDeliveredOrCompleted) {
                        return new Date(b.created_at) - new Date(a.created_at); // Newest first
                      }
                      return new Date(a.created_at) - new Date(b.created_at); // Oldest first
                    })
                    .map((item) => (
                      <div
                        key={item.id}
                        className="laundry-box"
                        onClick={() => handleBoxClick(item.id, item.status)}
                      >
                        <div
                          className="status-label"
                          style={{ color: getStatusDetails(item.status).color }}
                        >
                          {getStatusDetails(item.status).label}
                        </div>
                        <p><strong>Property ID: #{formatPropertyId(item.property_id)}</strong></p>
                        <p>
                          <strong>
                            {item.client_name || 'Unknown Client'} - {item.building_name || 'N/A'} - Unit {item.unit_number || 'N/A'}
                          </strong>
                        </p>
                        <p>Created: {new Date(item.created_at).toLocaleDateString()}</p>

                        {/* Confirm button for 'created' or 'flagged' */}
                        {(item.status === 'created' || item.status === 'flagged') && (userRole === 'manager' || userRole === 'warehouse') && (
                          <button
                            onClick={(e) =>
                              handleConfirm(item.id, e, {
                                propertyId: item.property_id,
                                propertyDisplay: `${item.client_name || 'Unknown Client'} - ${item.building_name || 'N/A'} - Unit ${item.unit_number || 'N/A'}`,
                              })
                            }
                            className="confirm-button"
                          >
                            Confirm Laundry Count
                          </button>
                        )}

                        {/* Upload Photos button for 'before_photo' */}
                        {item.status === 'before_photo' && (userRole === 'manager' || userRole === 'warehouse') && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/laundry/confirm/${item.id}`, { state: { startStep: 2 } });
                            }}
                            className="confirm-button"
                          >
                            Upload Photos
                          </button>
                        )}

                        {/* Packing button for 'processing' or 'stains_reported' */}
                        {(item.status === 'processing' || item.status === 'stains_reported') &&
                          (userRole === 'manager' || userRole === 'warehouse') && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/laundry/packing/${item.id}`);
                              }}
                              className="confirm-button"
                            >
                              Packing
                            </button>
                          )}
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default Laundry;
